import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Plus from "assets/forum/plus.svg";
import Question from "assets/forum/question.svg";
import Saved from "assets/forum/bookmark.svg";
import Icon from "assets/forum/Icon.svg";
import Circle from "assets/forum/circle.svg";
import axios from 'axios';
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import { decryptData } from "utilityFunction/encryption&Decryption";
import {
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  Paper,
  Select,
  MenuItem,
  Stack,
  CircularProgress,
  Avatar,
  Modal,
  Button
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

const ForumPage = () => {
  const [sortBy, setSortBy] = useState('popularity');
  const [newThreadTitle, setNewThreadTitle] = useState('');
  const [activeView, setActiveView] = useState('all'); // 'all' or 'user'
  const navigate = useNavigate();
  const { slug } = useParams();
  const [bookmarkedThreads, setBookmarkedThreads] = useState([]);
  const [userThreads, setUserThreads] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [threadTitle, setThreadTitle] = useState('');
  const [threadContent, setThreadContent] = useState('');
  const [bookmarkedThreadsMap, setBookmarkedThreadsMap] = useState({});

  // Using the useGetRequest hook for fetching threads
  const {
    data: threaddata,
    loading: loadingThreads,
    error: threadsError,
    getData: fetchThreads
  } = useGetRequest(`${API_ENDPOINTS.GET_ALL_THREADS}/${slug}`);
  console.log(threaddata);

  // Initialize threads with an empty array if data is null
  const threads = threaddata || [];


  useEffect(() => {
    fetchThreads();
  }, []);


  // Create new thread
  // Modal handlers
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setThreadTitle('');
    setThreadContent('');
  };

  // Create new thread
  const handleCreateThread = async () => {
    if (!threadTitle.trim() || !threadContent.trim()) return;

    try {
      await axios.post(API_ENDPOINTS.CREATE_NEW_THREAD, {
        title: threadTitle,
        content: threadContent,
        course_id: 29,
        tags: "General",
        is_announcement: 0,
        is_reply: 0
      });
      handleCloseModal();
      fetchThreads();
    } catch (error) {
      console.error('Error creating thread:', error);
    }
  };


  const handleAddResponse = (threadId) => {
    navigate(`/app/forum/${slug}/thread/${threadId}`);
  };

  const handleOpenThread = async (threadId) => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.GET_SINGLE_THREAD}/${threadId}`);
      if (response.data) {
        navigate(`/app/forum/${slug}/thread/${threadId}`);
      }
    } catch (error) {
      console.error('Error opening thread:', error);
    }
  };

  // Fetch threads based on active view
  const fetchAppropriateThreads = async () => {
    try {
      if (activeView === 'user') {
        const response = await axios.get(API_ENDPOINTS.VIEW_MY_THREADS);
        setUserThreads(response.data.existingThread || []);
      } else if (activeView === 'saved') {
        const responsee = await axios.get(API_ENDPOINTS.VIEW_ALL_BOOKMARKS);
        console.log(responsee);
        console.log(responsee.data.bookmarks);
       setBookmarkedThreads(responsee.data.bookmarks || []);
      } else {
        fetchThreads(); // Original all threads fetch
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  };



  useEffect(() => {
    fetchAppropriateThreads();
  }, [activeView]);


 // Add this useEffect to initialize bookmark states
 useEffect(() => {
  if (threaddata) {
    const bookmarkStates = {};
    threaddata.forEach(thread => {
      const isBookmarked = bookmarkedThreads.some(
        bookmark => bookmark.thread_id === thread.thread_id
      );
      bookmarkStates[thread.thread_id] = isBookmarked;
    });
    setBookmarkedThreadsMap(bookmarkStates);
  }
}, [threaddata, bookmarkedThreads]);

  // Update the toggle bookmark function
  const handleToggleBookmark = async (threadId) => {
    try {
      await axios.post(API_ENDPOINTS.TOGGLE_BOOKMARK, {
        thread_id: threadId,
        thread_reply_id: null,
      });
      
      // Update only the specific thread's bookmark state
      setBookmarkedThreadsMap(prev => ({
        ...prev,
        [threadId]: !prev[threadId]
      }));

      // Refresh threads after toggling bookmark
      fetchAppropriateThreads();
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  };



  // Toggle like function
  const handleToggleLike = async (threadId, threadReplyId = null) => {
    try {
      await axios.post(API_ENDPOINTS.TOGGLE_LIKE, {
        thread_id: threadId,
        thread_reply_id: threadReplyId
      });
      // Refresh threads after toggling like
      fetchAppropriateThreads();
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  // Update sidebar click handlers
  const handleSidebarItemClick = (view) => {
    setActiveView(view);
  };

  // Get current threads based on active view
 // Modified getCurrentThreads function to properly handle bookmarked threads
 const getCurrentThreads = () => {
  switch (activeView) {
    case 'user':
      return userThreads;
    case 'saved':
      return bookmarkedThreads.map(bookmarks => ({
        ...bookmarks.thread,
        thread_id: bookmarks.thread_id,
      }));
    default:
      return threaddata || [];
  }
};


  // Update the thread listing to use getCurrentThreads()
  const displayThreads = getCurrentThreads();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now - date); // Difference in milliseconds

    // Calculate differences in units
    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    // Adjust "Just now" buffer to cover up to 5 minutes
    if (diffMinutes < 5) {
      return "Just now";
    } else if (diffMinutes < 60) {
      return `${diffMinutes} ${diffMinutes === 1 ? "minute" : "minutes"} ago`;
    } else if (diffHours < 24) {
      return `${diffHours} ${diffHours === 1 ? "hour" : "hours"} ago`;
    } else {
      return `${diffDays} ${diffDays === 1 ? "day" : "days"} ago`;
    }
  };


  const getInitials = (firstName, lastName) => {
    try {
      const decryptedFirst = firstName ? decryptData(firstName) : '';
      const decryptedLast = lastName ? decryptData(lastName) : '';

      if (!decryptedFirst && !decryptedLast) return "U";


      // Store initials in a variable
      const firstInitial = decryptedFirst ? decryptedFirst.charAt(0).toUpperCase() : '';
      const lastInitial = decryptedLast ? decryptedLast.charAt(0).toUpperCase() : '';
      const userInitials = `${firstInitial}${lastInitial}`;

      return userInitials || "U"; // Return U if userInitials is empty

    } catch (error) {
      console.error("Error decrypting name data:", error);
      return "U";
    }
  };

  // Log user data when it's present in the threads
  useEffect(() => {
    if (threaddata) {
      threaddata.forEach(thread => {
        if (thread.User) {
          console.log('User Data:', {
            first_name: thread.User.first_name,
            last_name: thread.User.last_name,
            profile_pic: thread.User.profile_pic,
            username: thread.User.username
          });
        }
      });
    }
  }, [threaddata]);

  console.log(threads);

  return (
    <Grid container sx={{ height: "100%", width: "100%", display: "flex" }}>
      {/* Sidebar - Keeping the existing sidebar code */}
      <Grid
        item
        xs={3}
        sx={{
          bgcolor: 'rgba(245, 250, 255, 1)',
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          position: "relative",
          overflowY: "auto",
          alignItems: "center",
          borderRight: "1px solid rgba(0, 0, 0, 0.1)"
        }}
      >
        {/* Your existing sidebar code remains the same */}
        <Box sx={{
          width: "100%",
          p: 3,
          overflowY: "auto",
          flex: 1,
          "& .list-container": {
            gap: 0,
          }
        }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              ml: 2,
              mt: 1,
              fontFamily: "Gilroy SemiBold",
              fontSize: "25px",
              fontWeight: 400,
              lineHeight: "50px",
              color: "rgba(36, 34, 34, 1)",
            }}
          >
            MTS Forum
          </Typography>

          <List sx={{ width: "100%", p: 0 }}>
            <ListItem
              button
              onClick={() => handleSidebarItemClick('all')}
              sx={{ mb: 0, px: 2, py: 1 }}>
              <Box
                component="img"
                src={Plus}
                alt="Home"
                sx={{ width: "25px", height: "25px", mr: 2 }}
              />
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Gilroy Medium",
                      fontSize: "18px",
                      color: activeView === 'all' ? 'rgba(57, 204, 204, 1)' : 'rgba(43, 61, 79, 1)'
                    }}
                  >
                    Home
                  </Typography>
                }
              />
            </ListItem>

            <ListItem
              button
              onClick={() => handleSidebarItemClick('user')}
              sx={{ mb: 0, px: 2, py: 1 }}>
              <Box
                component="img"
                src={Question}
                alt="Threads"
                sx={{ width: "25px", height: "25px", mr: 2 }}
              />
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Gilroy Medium",
                      fontSize: "18px",
                      color: activeView === 'user' ? 'rgba(57, 204, 204, 1)' : 'rgba(43, 61, 79, 1)'
                    }}
                  >
                    Your Threads
                  </Typography>
                }
              />
            </ListItem>

            <ListItem
              button
              onClick={() => handleSidebarItemClick('saved')}
              sx={{ mb: 0, px: 2, py: 1 }}>
              <Box
                component="img"
                src={Saved}

                alt="Saved"
                sx={{ width: "25px", height: "25px", mr: 2 }}
              />
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Gilroy Medium",
                      fontSize: "18px",
                      color: activeView === 'saved' ? 'rgba(57, 204, 204, 1)' : 'rgba(43, 61, 79, 1)'
                    }}
                  >
                    Saved
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>

        <Box sx={{ p: 7, whiteSpace: 'nowrap' }}>
          <ListItem
            button
            onClick={() => navigate(`/app/course/${slug}/learn`)}

          >
            <Typography
              sx={{
                fontFamily: "Gilroy Regular",
                fontSize: "16px",
                lineHeight: "25px",
                color: "rgba(43, 61, 79, 1)",
                ml: -9
              }}
            >
              {"<"} Back to Course Dashboard
            </Typography>
          </ListItem>
        </Box>
      </Grid>

      {/* Main Content */}
      <Grid
        item
        xs={9}
        sx={{
          bgcolor: "rgba(245, 250, 255, 1)",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "auto",
          p: 5
        }}
      >
        {/* Add New Thread Input */}
        <Paper
          elevation={0}
          sx={{
            p: 2,
            mb: 3,
            position: 'relative',
            borderRadius: '8px',
          }}
        >
          <Box
            onClick={handleOpenModal}
            sx={{
              p: 0.1,

              cursor: 'pointer',
              color: 'rgba(100, 110, 131, 1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography sx={{ fontFamily: "Gilroy Medium", fontSize: "16px" }}>
              Create new thread
            </Typography>
            <Box
              sx={{
                width: '66px',
                height: '42px',
                borderRadius: '4px',
                border: '2px solid #39CCCC',
                backgroundColor: '#39CCCC',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img src={Icon} alt="Create Thread" style={{ width: '21px', height: '21px' }} />
            </Box>
          </Box>
        </Paper>


        {/* Thread Creation Modal */}
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Paper sx={{ p: 4, maxWidth: '500px', width: '90%', borderRadius: '8px', maxHeight: '90vh', overflow: 'auto' }}>
            <Typography variant="h6" sx={{ mb: 3, fontFamily: "Gilroy SemiBold" }}>
              Create New Thread
            </Typography>

            <TextField
              fullWidth
              label="Title"
              value={threadTitle}
              onChange={(e) => setThreadTitle(e.target.value)}
              sx={{
                mb: 3

              }}
            />

            <TextField
              fullWidth
              label="Description"
              value={threadContent}
              onChange={(e) => setThreadContent(e.target.value)}
              multiline
              rows={4}
              sx={{ mb: 3 }}
            />

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  color: 'rgba(43, 61, 79, 1)',
                  fontFamily: "Gilroy Medium"
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateThread}
                sx={{
                  bgcolor: '#39CCCC',
                  color: 'white',
                  '&:hover': { bgcolor: '#33B5B5' },
                  fontFamily: "Gilroy Medium"
                }}
              >
                Add Thread
              </Button>
            </Box>
          </Paper>
        </Modal>




        {/* Sort Dropdown */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1, borderRadius: '8px', mt: 3, py: 1, borderColor: 'rgba(100, 110, 131, 1)' }}>
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            size="small"
            borderRadius="8px"
            py="1"
            IconComponent={() => <KeyboardArrowDown
              sx={{
                position: 'absolute',
                right: '10px',
              }}
            />}
            sx={{
              width: '150px',
              height: '35px',
              bgcolor: 'rgba(245, 250, 255, 1)',
              '& .MuiSelect-select': {
                fontFamily: 'Gilroy Medium',
                fontSize: '14px'
              }
            }}
          >
            <MenuItem value="popularity">Popularity</MenuItem>
            <MenuItem value="recent">Most Recent</MenuItem>
            <MenuItem value="replies">Most Replies</MenuItem>
          </Select>
        </Box>

        {/* Loading State */}
        {loadingThreads && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Error State */}
        {threadsError && (
          <Typography color="error" sx={{ mt: 3 }}>
            Error loading threads. Please try again later.
          </Typography>
        )}

        {!loadingThreads && !threadsError && threads.length === 0 && (
          <Typography sx={{ mt: 3, textAlign: 'center' }}>
            No threads found. Be the first to create one!
          </Typography>
        )}

        {/* Thread Listings */}
        <Stack spacing={2} mt={3} flexDirection={'column-reverse'}>
          {displayThreads.map(thread => (
            <Paper
              key={thread.thread_id}
              elevation={0}
              sx={{
                p: 3,
                bgcolor: 'white',
                borderRadius: '8px',
                flexDirection: 'column-reverse',
              }}
            >
              <Box sx={{ display: 'flex', gap: 4 }}>
                {/* Left side - User info */}
                <Box sx={{ minWidth: '120px', display: 'flex', alignItems: 'center', mb: 8,   cursor: 'pointer' } }  onClick={() => handleAddResponse(thread.thread_id)}>
                  <Avatar
                    sx={{
                      width: 33,
                      height: 33,
                      mb: 2,
                      mr: 2,
                      bgcolor: "rgba(43, 61, 79, 1)",
                      color: "#fff",
                      backgroundImage: thread.User?.profile_pic   // Assuming you have this in your thread data
                        ? `url(${API_ENDPOINTS.PROFILE_PHOTO}/${encodeURIComponent(thread.userProfilePic)})`
                        : "none",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    {!thread.User?.profile_pic && getInitials(thread.User?.first_name, thread.User?.last_name)}
                  </Avatar>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Gilroy SemiBold',
                        fontSize: '16px',
                        color: 'rgba(59, 82, 105, 1)',
                        mb: 0.5
                      }}
                    >
                     {
  thread.User
    ? decryptData(thread.User.first_name).charAt(0).toUpperCase() + decryptData(thread.User.first_name).slice(1)
    : `User ${thread.created_by}`
}

                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: 'Gilroy Regular',
                        fontSize: '14px',
                        color: 'rgba(100, 110, 131, 1)',
                        minWidth: '100px', // Ensures a consistent width
                       
                        display: 'inline-block', // Prevents wrapping
                      }}
                    >
                      {formatDate(thread.added_on)}
                    </Typography>
                  </Box>
                </Box>

                {/* Right side - Thread content */}
                <Box sx={{ flex: 1 }}>
                  <Typography
                    onClick={() => handleAddResponse(thread.thread_id)}
                    sx={{
                      fontFamily: 'Gilroy Medium',
                      fontSize: '18px',
                      color: 'rgba(43, 61, 79, 1)',
                      mb: 1,
                        cursor: 'pointer'
                    }}
                  >
                    {thread.title}
                  </Typography>
                  <Typography
                    onClick={() => handleAddResponse(thread.thread_id)}
                    sx={{
                      fontFamily: 'Gilroy Regular',
                      fontSize: '16px',
                      color: 'rgba(90, 90, 90, 1)',
                      mb: 2,
                        cursor: 'pointer'
                    }}
                  >
                    {thread.content}

                  </Typography>


                  {/* Action buttons */}
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box
                     onClick={() => handleToggleBookmark(thread.thread_id)}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box

                        component="img"
                        src={Saved}
                        alt="Save"
                        sx={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                          backgroundColor: 'rgba(251, 251, 251, 1)',
                          borderRadius: '4px',
                          backgroundWidth: '30px',
                          backgroundHeight: '37px',
                        }}
                      />
                      <Typography sx={{ color: 'rgba(100, 110, 131, 1)', fontSize: '14px', fontFamily: 'Gilroy Regular', mr: 2 }}>
                      {bookmarkedThreadsMap[thread.thread_id] ? 'Saved' : 'Save'}
                      </Typography>


                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          cursor: 'pointer'
                        }}
                        onClick={() => handleOpenThread(thread.thread_id)}
                      >
                        <Box
                          component="img"
                          src={Circle}
                          alt="Response"
                          sx={{
                            width: '19px',
                            height: '19px',
                            backgroundColor: 'rgba(251, 251, 251, 1)',
                            borderRadius: '4px',
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: 'Gilroy Regular',
                            fontSize: '14px',
                            color: 'rgba(110, 110, 131, 1)',
                          }}
                        >
                          Add response
                        </Typography>
                      </Box>
                    </Box>

                    <Box

                      sx={{
                        bgcolor: 'rgba(230, 255, 238, 1)',
                        px: 3,
                        py: 1,
                        borderRadius: '4px',
                        cursor: 'pointer'
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Gilroy Regular',
                          fontSize: '14px',
                          color: 'rgba(100, 110, 131, 1)'
                        }}
                      >
                        {thread.MasterThreadStatus?.name || 'OPEN'}
                      </Typography>

                    </Box>
                  </Box>

                </Box>
              </Box>
            </Paper>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ForumPage;