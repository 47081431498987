import React, { useState, useContext, useRef, useEffect } from "react";
// import Plyr from "plyr-react";
// import 'plyr-react/plyr.css';
import LessonContext from "context/lessonContext/LessonContext";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ReactPlayer from "react-player";
import UserContext from "context/userContext/UserContext";

const VideoPlayerBunnyNet = ({ videoUrl, thumbnailUrl, content }) => {
  const { updateLessonCompletionData } = useContext(LessonContext);
  const { userData } = useContext(UserContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isInstitutionUser, setIsInstitutionUser] = useState(false);
  const playerRef = useRef(null);

  // const handleProgress = ({ played }) => {
  //   const watchPercentage = played * 100;

  //   if (watchPercentage >= 85 && !isCompleted) {
  //     updateLessonCompletionData(content);
  //     setIsCompleted(true); // Mark as completed to prevent multiple calls
  //   }
  // };
  useEffect(() => {
    if (content) {
      updateLessonCompletionData(content);
    }
    // Empty dependency array ensures this runs only once
  }, []);
  

  useEffect(() => {
    if (Array.isArray(userData) && userData[0] && userData[0].is_institution_user === 1) {
      setIsInstitutionUser(true);
    } else {
      setIsInstitutionUser(false);
    }
  }, [userData]);
  
  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div className="bunney-net-player">
      {!isPlaying && (
        <div className="react-player__preview" onClick={handlePlay}>
          <img src={`https://vz-fefc3984-6fd.b-cdn.net/${thumbnailUrl}`} alt="Video Thumbnail" className="thumbnail" />
          <button className="play-button">▶</button>
        </div>
      )}
      {isPlaying && (
        <iframe
          src={`https://iframe.mediadelivery.net/embed/370995/${videoUrl}?autoplay=false&loop=false&muted=${isInstitutionUser ? true : false}&preload=true&responsive=true`}
          loading="lazy"
          className="responsive-iframe"
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default VideoPlayerBunnyNet;