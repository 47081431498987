import React, { useState, useEffect } from 'react';
import { Box, Paper, TextField, IconButton, Snackbar, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { io } from 'socket.io-client';
import { API_ENDPOINTS } from 'config/ApiConfig';
import EmojiPicker from 'emoji-picker-react';
import sendImg from "assets/chat/send.svg";
import emojiImg from "assets/chat/face-smile.svg";
import fileImg from "assets/chat/file.svg";

const socket = io(API_ENDPOINTS.SOCKET_URL); // Replace with your server URL

function MessageInput({ user_id, chatroom_id }) {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State for emoji picker

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
    });

    return () => {
      socket.off('connect');
    };
  }, []);

  const handleSendMessage = () => {
    if (message.trim() || file) {
      const messageData = {
        message: message.trim(),
        user_id,
        chatroom_id,
      };
  
      if (file) {
   
        const reader = new FileReader();
        reader.onload = () => {
          console.log('File read result:', reader.result);
  
          messageData.file = {
            name: file.name,
            type: file.type,
            data: reader.result, // Base64-encoded file data
          };
 
          // Emit the message with the file
          socket.emit('sendMessage', messageData, (response) => {
            console.log('Server response:', response);
          });
          console.log('message data --. ', messageData)
  
          setMessage('');
          setFile(null);
        };
  
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };
  
        reader.readAsDataURL(file); // Read file as Base64
      } else {
        // Emit the message without the file
        console.log('Message data being sent:', messageData);
  
        socket.emit('sendMessage', messageData, (response) => {
          console.log('Server response:', response);
        });
  
        setMessage('');
      }
    } else {
      console.error('Message is empty');
    }
  };
  

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

      if (allowedTypes.includes(fileType)) {
        setFile(selectedFile);
      } else {
        setError('Only PNG, JPG, JPEG images, and PDFs are allowed.');
        setFile(null);
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const addEmoji = (emojiObject) => {
    // Log the entire emoji object to understand its structure
    console.log(emojiObject);
    
    // Append the selected emoji to the message
    setMessage(prevMessage => prevMessage + emojiObject.emoji || emojiObject.native || emojiObject.character);
    
    //closing the emoji picker after selecting
    setShowEmojiPicker(false);
  };

  return (
    <Box sx={{ position: 'relative' }}>
    {/* Emoji Picker */}
    {showEmojiPicker && (
      <Box 
        id="emoji-picker-container"
        sx={{ 
          position: 'absolute', 
          bottom: '100%', 
          right: 0, 
          zIndex: 10,
          width: '300px' 
        }}
      >
        <EmojiPicker 
          onEmojiClick={addEmoji}
          width="100%"
        />
      </Box>
    )}

    
      {/* Display the selected file name */}
      {file && (
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pl: 2  }}> 
          <Typography variant="body2">{file.name}</Typography>
          <IconButton size="small" onClick={handleRemoveFile}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          bgcolor: "rgba(246, 248, 250, 1)",
          borderRadius: "8px",
          position: "relative",
    zIndex: 1
        }}
      >
        <TextField
          fullWidth
          variant="standard"
          placeholder="Write a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{ mx: 2 }}
        />
        
       {/* Emoji Picker Button */}
       <IconButton 
          id="emoji-button"
          onClick={() => setShowEmojiPicker(!showEmojiPicker)} 
          sx={{ color: "primary.main", p: 1 }}
        >
          <Box
            component="img"
            src={emojiImg}
            alt="Emoji"
            sx={{ width: "24px", height: "24px" }} 
          />
        </IconButton>
        <IconButton onClick={() => document.getElementById('fileInput').click()} sx={{ color: "primary.main", p: 1 }}> {/* Removed default padding for precise styling */}
  <Box
    component="img"
    src={fileImg}
    alt="File"
    sx={{ width: "24px", height: "24px" }} 
  />
</IconButton>
        
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".png, .jpg, .jpeg, .pdf"
        />
        
        <IconButton onClick={handleSendMessage} sx={{ color: "primary.main", p: 1 }}> {/* Removed default padding for precise styling */}
  <Box
    component="img"
    src={sendImg}
    alt="Send"
    sx={{ width: "24px", height: "24px" }} 
  />
</IconButton>
      </Paper>

      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={3000}
          onClose={() => setError('')}
          message={error}
        />
      )}
    </Box>
  );
}

export default MessageInput;