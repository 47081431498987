import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import LessonContext from "context/lessonContext/LessonContext";
import usePostRequest from "hooks/usePostRequest";
import QuizFeedback from "./QuizFeedback";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import QuizTimer from "./TimerCard";
import QuizResultCard from "./QuizResultCard";

const MultipleOptionsQuiz = ({ content }) => {
  const { lessonInfo, sortedLessonData, lessonIdentifiers } =
    useContext(LessonContext);
  const { data: saveQuixResponseData, postData: saveOptionQuizResponse } =
    usePostRequest(API_ENDPOINTS.SAVE_OPTION_QUIZ_PROGRESS);
  const [display, setLoading] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [activeOption, setActiveOption] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const { notifyUser } = useContext(NofityUserContext);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { updateLessonCompletionData } = useContext(LessonContext);

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  const areAllQuestionsAnswered = () => {
    if (!contentDetails || !contentDetails.multipleOptions) return false;
    return contentDetails.multipleOptions.every(
      (question) => activeOption[question.question_id] !== undefined
    );
  };

  useEffect(() => {
    setTimerKey(timerKey + 1);
  }, [quizStarted]);

  useEffect(() => {
    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find(
        (item) => item.content_id === content
      );

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
        setLoading(true);
      }
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails && contentDetails.multipleOptions) {
      setQuizData(contentDetails.multipleOptions[currentQuestionIndex]);
    }
  }, [contentDetails, currentQuestionIndex]);

  useEffect(() => {
    if (contentDetails && contentDetails.multipleOptions.length > 0) {
      const firstQuestion = contentDetails.multipleOptions[0];
      setEstimatedCompletionTime(firstQuestion.estimated_completion_time);
    }
  }, [contentDetails]);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(
        contentDetails1.RefContentType.name,
        contentDetails1.contentDetails.multipleOptions[0].quiz_op_id,
        lessonIdentifiers?.course_id
      );
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);

  useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      if (
        !quizStatResponse.quizfeedBackStat ||
        quizStatResponse.quizfeedBackStat.length === 0
      ) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      if (
        !quizStatResponse.quizStatDetails ||
        quizStatResponse.quizStatDetails.length === 0
      ) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true);
      }
    }
  }, [quizStatResponse, contentDetails1]);

  useEffect(() => {
    if (quizStatApiResponse) {
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_op_id === detail.response_quiz_op_id
        );

        const question =
          detail.MasterQuestion && detail.MasterQuestion
            ? detail.MasterQuestion.question
            : null;
        const questionScore =
          detail.MasterQuestion && detail.MasterQuestion
            ? detail.MasterQuestion.score
            : null;

        return {
          id: detail.response_quiz_op_id,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback
            ? feedback.feedback.updated_on
            : null,
          question_score: questionScore,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });
      setModalData(combinedData);
    }
  }, [quizStatApiResponse]);

  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error fetching quiz stats:", error);
      notifyUser(
        "Error",
        "Failed to fetch quiz statistics. Please try again.",
        "error"
      );
    }
  };

  const handleOptionClick = (questionId, optionId) => {
    setActiveOption((prev) => ({
      ...prev,
      [questionId]: optionId,
    }));
    setErrorMessage("");
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };

  const handleNavigation = (direction) => {
    if (quizData) {
      const { question_id } = quizData;

      if (!activeOption[question_id] && direction === "next") {
        notifyUser(
          "Select Option",
          "Please select an option before proceeding.",
          "warning"
        );
        return;
      }

      const totalQuestions = contentDetails.multipleOptions.length;
      let newQuestionIndex =
        direction === "next"
          ? currentQuestionIndex + 1
          : currentQuestionIndex - 1;

      newQuestionIndex = Math.max(
        0,
        Math.min(newQuestionIndex, totalQuestions - 1)
      );

      setCurrentQuestionIndex(newQuestionIndex);
      setQuizData(contentDetails.multipleOptions[newQuestionIndex]);
      setErrorMessage("");
    }
  };

  const handleSave = async () => {
    if (!areAllQuestionsAnswered()) {
      const unansweredQuestions = contentDetails.multipleOptions
        .filter((question) => !activeOption[question.question_id])
        .map((_, index) => index + 1)
        .join(", ");

      notifyUser(
        "Incomplete Quiz",
        `Please answer all questions before saving. Missing answers for question(s): ${unansweredQuestions}`,
        "warning"
      );
      return;
    }

    setIsSaving(true);
    const endTime = new Date();
    const timeTaken = ((endTime - startTime) / 1000 / 60).toFixed(2);

    try {
      for (const question of contentDetails.multipleOptions) {
        const selectedOptionId = activeOption[question.question_id];
        const selectedOption = question.options.find(
          (option) => option.option_id === selectedOptionId
        );
        const selectedText = selectedOption ? selectedOption.option_name : "";

        const payLoad = {
          course_id: lessonIdentifiers.course_id,
          quiz_op_id: question.quiz_op_id,
          quiz_id: question.question_id,
          option_id: selectedOptionId,
          answer_text: selectedText,
          completion_time: timeTaken,
        };

        await saveOptionQuizResponse(payLoad);
      }

      setQuizSubmitted(true);
      updateLessonCompletionData(content);
    } catch (error) {
      console.error("Failed to submit quiz responses:", error);
      notifyUser(
        "Error",
        "Failed to save quiz responses. Please try again.",
        "error"
      );
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(
        contentDetails1.RefContentType.name,
        contentDetails1.contentDetails.multipleOptions[0].quiz_op_id,
        lessonIdentifiers?.course_id
      );
      const timer = setTimeout(() => {
        setQuizSubmitted(false);
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);

  const handleTimeUp = async () => {
    setActiveOption({});
  };

  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return (
      <QuizResultCard
        data={modalData}
        title={quizStatApiResponse.quizStatDetails[0].MasterQuizOption.quiz_name}
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        status="Submitted"
      />
    );
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return (
      <QuizResultCard
        data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizOption.quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
    );
  }

  return display ? (
    <>
      {!quizStarted && (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      )}
      {quizStarted && (
        <Box className="SAQ_wrapper" sx={{ position: "relative" }}>
          <Box className="SAQ_wrapper__Question-box">
            <Box className="SAQ_wrapper__Question-box--number">
              <Typography className="SAQ_wrapper__Question-box--number-text">
                Question {currentQuestionIndex + 1} /{" "}
                {contentDetails.multipleOptions.length}
              </Typography>
            </Box>
            <Typography className="SAQ_wrapper__Question-box--question">
              {quizData.question}
            </Typography>
          </Box>
          <Box className="SAQ_wrapper__answer-wrapper">
            <Box
              className="SAQ_wrapper__options-wrapper"
              sx={{ width: "100%" }}
            >
              {estimatedCompletionTime && (
                <QuizTimer
                  key={timerKey}
                  estimatedCompletionTime={estimatedCompletionTime}
                  handleComplete={handleTimeUp}
                />
              )}
              <Grid container spacing={4} sx={{ padding: "20px" }}>
                {quizData.options && quizData.options.length > 0 ? (
                  quizData.options.map((option) => (
                    <Grid item xs={12} sm={6} key={option.option_id}>
                      <Button
                        fullWidth
                        className={
                          activeOption[quizData.question_id] ===
                          option.option_id
                            ? "SAQ_wrapper__options-wrapper--option-button--active"
                            : "SAQ_wrapper__options-wrapper--option-button"
                        }
                        onClick={() =>
                          handleOptionClick(
                            quizData.question_id,
                            option.option_id
                          )
                        }
                        sx={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          padding: "16px",
                          margin: "8px",
                          minWidth: "250px",
                          whiteSpace: "normal",
                          lineHeight: "1.4",
                          "&:hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        {option.option_name}
                      </Button>
                    </Grid>
                  ))
                ) : (
                  <Typography>No options available for this question</Typography>
                )}
              </Grid>
            </Box>

            <Box
              className="SAQ_wrapper-navigate"
              id="no-bg"
              sx={{
                marginBottom: "1px",
              }}
            >
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                id="white-bg"
                onClick={() => handleNavigation("previous")}
                disabled={currentQuestionIndex === 0}
              >
                Previous
              </Button>
              {currentQuestionIndex ===
              contentDetails.multipleOptions.length - 1 ? (
                <Button
                  className="SAQ_wrapper-navigate__button"
                  variant="outlined"
                  id="white-bg"
                  onClick={handleSave}
                  disabled={!areAllQuestionsAnswered() || isSaving}
                >
                  {isSaving ? "Saving..." : "Save"}
                </Button>
              ) : (
                <Button
                  className="SAQ_wrapper-navigate__button"
                  variant="outlined"
                  id="white-bg"
                  onClick={() => handleNavigation("next")}
                  disabled={!activeOption[quizData.question_id]}
                >
                  Next
              </Button>
            )}
          </Box>
          <LinearProgress
            className="SAQ_wrapper__progress"
            variant="determinate"
            value={((currentQuestionIndex + 1) / contentDetails.multipleOptions.length) * 100}
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%'
            }}
          />
        </Box>
      </Box>
    )}
  </>
) : (
  <Typography></Typography>
);
};

export default MultipleOptionsQuiz;