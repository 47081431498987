import { createContext, useCallback, useState } from "react";

const UserContext = createContext();

export default UserContext;

export const UserProvider = ({ children }) => {
  //state to store the user info
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // New function to check if user is institutional
  const isInstitutionalUser = useCallback(() => {
    const userInfo = userData.find(item => typeof item === "object" && "user_id" in item);
    return userInfo?.is_institution_user === 1;
  }, [userData]);

  const onLogin = useCallback((newData) => {
    setIsLoading(true);
    if (newData) {
      setUserData((prevData) => {
        // Function to check if two objects are equal
        const isEqual = (obj1, obj2) =>
          JSON.stringify(obj1) === JSON.stringify(obj2);

        // Function to find matching item in prevData
        const findMatchingItem = (item) => {
          for (let key in newData) {
            if (item[key] && isEqual(item[key], newData[key])) {
              return true;
            }
          }
          return false;
        };

        // Check if there's an existing entry that matches any part of newData
        const existingIndex = prevData.findIndex(findMatchingItem);

        if (existingIndex > -1) {
          // Update existing entry
          const updatedData = [...prevData];
          updatedData[existingIndex] = {
            ...updatedData[existingIndex],
            ...newData, // Merge new data with existing data
          };
          return updatedData;
        } else {
          // Add new entry
          return [...prevData, newData];
        }
      });
    }
    console.log ("usedata", userData);
    setIsLoading(false);
  }, []);

  //to remove the value from the context
  const onLogout = () => {
    setUserData([]);
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
  };

  return (
    <UserContext.Provider value={{ userData, onLogin, onLogout, isLoading , isInstitutionalUser }}>
      {children}
    </UserContext.Provider>
  );
};
