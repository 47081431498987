import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import LessonContext from "context/lessonContext/LessonContext";

const ImageContent = ({ content }) => {
  const { lessonInfo, updateLessonCompletionData, lessonIdentifiers } = useContext(LessonContext);

  const [quizData, setQuizData] = useState(null);
  const [contentDetails, setContentDetails] = useState(null);

  useEffect(() => {
    if (content) {
      updateLessonCompletionData(content);
    }
    // Empty dependency array ensures this runs only once
  }, []);
  

  useEffect(() => {
    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find(item => item.content_id === content);

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
      } else {
        // console.log("Content not found for the provided content_id:", content);
      }
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails) {
      setQuizData(`${API_ENDPOINTS.GET_IMAGE}/${contentDetails.name}`);
    }
  }, [contentDetails]);

  return quizData ? (
    <Box className="IC_wrapper">
      {/* Display only the image */}
      <Box className="IC_wrapper__image-container">
        <img
          src={quizData}
          alt="Quiz illustration"
          className="IC_wrapper__image"
        />
      </Box>
    </Box>
  ) : (
    <Typography>Loading quiz data...</Typography>
  );

};

export default ImageContent;
