import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "context/userContext/UserContext";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { Box, Link, Skeleton, Typography, useMediaQuery } from "@mui/material";
import ProfilePicture from "components/common/profilePicture/ProfilePicture";
import { ChevronDown } from "react-feather";
import { DropdownSkeleton } from "components/common/skeleton/SkeletonCard";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(() => ({}));

const sm = "(max-width: 599px)";

export default function Dropdown() {
  const { userData, onLogout, isLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleNavigation = (path) => {
    handleClose();
    navigate(path);
  };

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const isSmallScreen = useMediaQuery(sm);
  const isInstitutionUser = userData[0]?.is_institution_user === 1;

  useEffect(() => {
    if (!isLoading && userData && userData.length > 0) {
      const userObject = userData.find(
        (item) => typeof item === "object" && "user_id" in item
      );
      if (userObject && userObject !== userInfo) {
        setUserInfo(userObject);
      }
    }
  }, [userData, isLoading]);

  return (
    <div className="dropdown">
      {userInfo ? (
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          className="dropdown__button capitalize"
          onClick={handleClick}
        >
          {userInfo ? `${userInfo.first_name}` : "N"}
          <ChevronDown
            className="chevron-down-logo"
            style={{
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          />
        </Button>
      ) : (
        <Skeleton
          variant="rounded"
          width={100}
          height={30}
          sx={{ paddingRight: 3 }}
        />
      )}

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="dropdown__menu"
      >
        <Box className="dropdown__profile">
          {userInfo ? (
            <ProfilePicture
              firstName={userInfo ? `${userInfo.first_name}` : "Loading..."}
              lastName={userInfo ? `${userInfo.last_name}` : "Loading..."}
              imageSize={65}
              imageUrl={userInfo ? `${userInfo.profile_pic}` : null}
            />
          ) : (
            <DropdownSkeleton />
          )}

          <Typography className="dropdown__name capitalize">
            {userInfo ? `${userInfo.first_name}` : "Loading..."}{" "}
            {userInfo ? `${userInfo.last_name}` : "Loading..."}
          </Typography>
          <Typography className="dropdown__id ">
            {userInfo ? `${userInfo.username}` : "Loading..."}{" "}
          </Typography>
        </Box>
        {isSmallScreen ? (
          <>
            <MenuItem
              onClick={() => handleNavigation("dashboard")}
              disableRipple
              className="dropdown__menu-item"
            >
              Dashboard
            </MenuItem>
            <MenuItem
              onClick={() => handleNavigation("my-course")}
              disableRipple
              className="dropdown__menu-item"
            >
              Courses
            </MenuItem>
          </>
        ) : (
          <MenuItem
            onClick={() => handleNavigation("my-course")}
            disableRipple
            className="dropdown__menu-item"
          >
            Courses
          </MenuItem>
        )}
        {!isInstitutionUser && (
          <>
            <MenuItem
              onClick={() => handleNavigation("settings")}
              disableRipple
              className="dropdown__menu-item"
            >
              Settings
            </MenuItem>
            <MenuItem
              onClick={() => handleNavigation("purchase-history")}
              disableRipple
              className="dropdown__menu-item"
            >
              Purchase History
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => handleNavigation("report-content")}
          disableRipple
          className="dropdown__menu-item"
        >
          Report Content
        </MenuItem>
        <MenuItem
          onClick={() => handleNavigation("help-center-1")}
          disableRipple
          className="dropdown__menu-item"
        >
          Help center
        </MenuItem>
        <MenuItem disableRipple className="dropdown__menu-item">
          <Link
            href="https://mytrueskills.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
            sx={{ textTransform: "none", color: "#646E83" }}
          >
            Privacy Policy
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogout();
          }}
          disableRipple
          className="dropdown__menu-item"
        >
          Sign Out
        </MenuItem>
      </StyledMenu>
    </div>
  );
}