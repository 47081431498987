import React from "react";
import { RouterProvider } from "react-router-dom";
import AdminRoutes from "routes";
import CustomizedSnackbars from "components/common/snackBar/SnackBar";
//import DictionaryTooltip from "Dictionary/Dictionary";
import "styles/styles.scss";


function App() {
  return (
    <>
      <RouterProvider router={AdminRoutes} />
      <CustomizedSnackbars />
      {/* <DictionaryTooltip /> */}
     

    </>
  );
}

export default App;
