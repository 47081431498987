import React, { useState, useEffect } from "react";
import { CourseInfo } from "components/common/courseInfo/CourseInfo";
import PaymentStatus from "components/module/app/purchaseHistory/PaymentStatus";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import MUIProgressBar from "components/module/app/purchaseHistory/MUIProgressBar";
import download from "assets/icons/download-01.svg";
import { API_ENDPOINTS } from "config/ApiConfig";

export default function PurchaseHistoryTable({ groupedCourses, onStatusChange, statusFilter }) {
  const [alignment, setAlignment] = useState(statusFilter);

  useEffect(() => {
    setAlignment(statusFilter);
  }, [statusFilter]);

  const handleDownloadClick = (invoiceId) => {
    const url = `${API_ENDPOINTS.GET_INVOICE}/${invoiceId}`;
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = invoiceId;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setAlignment(newStatus);
      onStatusChange(newStatus);
    }
  };

  // Adjusted column widths for better spacing
  const columnWidths = {
    no: '10%',
    status: '15%',
    course: '35%',
    progress: '25%',
    invoice: '15%'
  };

  return (
    <Box className="purchase-history-wrapper">
      <Box className="toggle-button-container" sx={{ 
        overflowX: 'auto', 
        width: '100%',
        marginBottom: '1rem'
      }}>
        <ToggleButtonGroup
          className="toggle-button-group"
          value={alignment}
          exclusive
          onChange={handleStatusChange}
          aria-label="Status Filter"
          sx={{
            minWidth: 'fit-content',
            '& .MuiToggleButton-root': {
              padding: { xs: '6px 12px', sm: '8px 16px' },
              whiteSpace: 'nowrap'
            }
          }}
        >
          <ToggleButton value="viewAll" className={alignment === "viewAll" ? "active" : "normal"}>
            View All
          </ToggleButton>
          <ToggleButton value="enrolled" className={alignment === "enrolled" ? "active" : "normal"}>
            Enrolled
          </ToggleButton>
          <ToggleButton value="completed" className={alignment === "completed" ? "active" : "normal"}>
            Completed
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <TableContainer 
        className="purchase-history-table-container" 
        component={Paper}
        sx={{
          overflowX: 'auto',
          maxWidth: '100vw',
          '& .MuiTable-root': {
            minWidth: { xs: '800px', sm: '650px' },
            tableLayout: 'fixed'
          }
        }}
      >
        <Box className="purchase-history-table-container__rectangle-bar" />
        <Table aria-label="purchase history table">
          <TableHead>
            <TableRow>
              <TableCell 
                className="purchase-history-table__cell-title"
                sx={{ 
                  width: columnWidths.no,
                  padding: '16px',
                  paddingRight: '24px'
                }}
              >
                No
              </TableCell>
              <TableCell 
                className="purchase-history-table__cell-title"
                sx={{ 
                  width: columnWidths.status,
                  padding: '16px',
                  paddingRight: '24px'
                }}
              >
                Status
              </TableCell>
              <TableCell 
                className="purchase-history-table__cell-title" 
                id="course" 
                sx={{ 
                  width: columnWidths.course,
                  padding: '16px',
                  paddingRight: '24px'
                }}
              >
                Course
              </TableCell>
              <TableCell 
                className="purchase-history-table__cell-title" 
                id="progress"
                sx={{ 
                  width: columnWidths.progress,
                  padding: '16px',
                  paddingRight: '24px'
                }}
              >
                Progress
              </TableCell>
              <TableCell 
                className="purchase-history-table__cell-title"
                sx={{ 
                  width: columnWidths.invoice,
                  padding: '16px',
                  paddingRight: '24px'
                }}
              >
                Invoice
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedCourses.map((course, index) => (
              <TableRow key={course.id}>
                <TableCell 
                  className="purchase-history-table__cell-id"
                  sx={{ 
                    padding: '16px',
                    paddingRight: '24px'
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell 
                  className="purchase-history-table__cell-status"
                  sx={{ 
                    padding: '16px',
                    paddingRight: '24px'
                  }}
                >
                  <PaymentStatus status={course.status} />
                </TableCell>
                <TableCell 
                  className="purchase-history-table__cell-course"
                  sx={{ 
                    padding: '16px',
                    paddingRight: '24px'
                  }}
                >
                  <CourseInfo course={course} />
                </TableCell>
                <TableCell 
                  className="purchase-history-table__cell-progress"
                  sx={{ 
                    padding: '16px',
                    paddingRight: '24px'
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <MUIProgressBar progress={course.progress} />
                  </Box>
                </TableCell>
                <TableCell 
                  className="purchase-history-table__cell-invoice"
                  sx={{ 
                    padding: '16px',
                  }}
                >
                  {course.showDownload && (
                    <Box 
                      className="purchase-history-table__cell-invoice-wrapper" 
                      onClick={() => handleDownloadClick(course.invoice)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      <Box
                        component="img"
                        src={download}
                        className="purchase-history-table__cell-invoice-icon"
                        sx={{ width: 20, height: 20 }}
                      />
                      <Typography
                        variant="body2"
                        className="purchase-history-table__cell-invoice-text"
                      >
                        Download
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}