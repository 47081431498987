import React, { createContext, useState, useCallback, useEffect } from "react";

const LessonContext = createContext();

export default LessonContext;

export const LessonProvider = ({ children }) => {
  const [lessonInfo, setLessonInfo] = useState([]);
  const [sortedLessonData, setSortedLessonData] = useState([]);
  const [lessonCompletionData, setLessonCompletionData] = useState([]);
  const [lessonIdentifiers, setLessonIdentifiers] = useState({
    course_id: null,
    chapter_id: null,
    lesson_id: null,
  });

  const [isNavigating, setIsNavigating] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false); // Store full-screen mode state
  const [fullScreenContent, setFullScreenContent] = useState(null); // Store full-screen content
  const [hiddenContent, setHiddenContent] = useState([]); // Store hidden content

  const triggerNavigation = () => {
    setIsNavigating(true);
  };

  useEffect(() => {
    if (sortedLessonData && lessonCompletionData) {
      // console.log('sortedLessonData -- >', sortedLessonData);
  
      const completedContentIds = sortedLessonData.flatMap((chapter) =>
        chapter.lessons
          .filter((lesson) => lesson.isCompleted) // Filter completed lessons
          .flatMap((lesson) => lesson.lesson_contents.map((content) => content.content_id)) // Extract content_id
      );
  
      // console.log('completedContentIds -- >', completedContentIds);
  
      setLessonCompletionData((prevData) => {
        const updatedData = prevData.map((item) => ({
          ...item,
          is_viewed: completedContentIds.includes(item.content_id)
            ? true
            : item.is_viewed,
        }));
  
        // Check if the new state is different from the current state
        const hasChanges = updatedData.some(
          (item, index) => item.is_viewed !== prevData[index].is_viewed
        );
  
        return hasChanges ? updatedData : prevData; // Only update if there are changes
      });
    }
  }, [sortedLessonData, lessonCompletionData]);
  
  
  // Log updated lessonCompletionData after state update
  // useEffect(() => {
  //   console.log('lessonCompletionData after update -- >', lessonCompletionData);
  // }, [lessonCompletionData]);
  


  const updateLessonCompletionData = (content_id) => {
    // console.log("function called");
    setLessonCompletionData((prevData) =>
        prevData.map((item) =>
            item.content_id === content_id
                ? { ...item, is_viewed: true }
                : item
        )
    );

    // console.log("lessonCompletionData in side the function:", lessonCompletionData);
};

  const storeLessonIdentifiers = (identifiers) => {
    setLessonIdentifiers((prev) => ({
      ...prev,
      ...identifiers,
    }));
  };
  
  const addLessonData = (dataArray) => {
    const lessonData = dataArray.map(item => {
        return {
            ...item,
            is_viewed: false
        };
    });
    setLessonCompletionData(lessonData);
}
  // const handleQuizSubmission = useCallback(
  //   (contentId) => {
  //     setLessonInfo((prevLessonInfo) =>
  //       prevLessonInfo.map((lesson) => {
  //         // Only update the specific lesson with matching content_id
  //         if (lesson.content_id === contentId) {
  //           return { ...lesson, is_full_screen: false }; // Set is_full_screen to false
  //         }
  //         return lesson;
  //       })
  //     );
      
  //     setIsFullScreen(false); // Exit full-screen mode
  //     setFullScreenContent(null); // Clear full-screen content
  //   },
  //   [setLessonInfo, setIsFullScreen, setFullScreenContent]
  // );
  
  const handleQuizSubmission = useCallback(
    (contentId) => {
      setLessonInfo((prevLessonInfo) => {
        const updatedLessons = prevLessonInfo.filter(
          (lesson) => lesson.content_id !== contentId // Remove the lesson with matching content_id
        );
  
        // Check for any remaining full-screen lessons
        const fullScreenLesson = updatedLessons.find(
          (lesson) => lesson.is_full_screen === true
        );
  
        // Update the full-screen state based on the remaining lessons
        if (fullScreenLesson) {
          setIsFullScreen(true);
          setFullScreenContent(fullScreenLesson);
        } else {
          setIsFullScreen(false);
          setFullScreenContent(null);
        }
  
        return updatedLessons; // Return the updated lesson info
      });
    },
    [setLessonInfo, setIsFullScreen, setFullScreenContent]
  );
  
  const handleDisableFullScreen = useCallback(
    (contentId) => {
      setLessonInfo((prevLessonInfo) => {
        // Map through the lessons and update `is_full_screen` to `false` for the matching content_id
        const updatedLessons = prevLessonInfo.map((lesson) =>
          lesson.content_id === contentId
            ? { ...lesson, is_full_screen: false }
            : lesson
        );
  
        // Check for any remaining full-screen lessons
        const fullScreenLesson = updatedLessons.find(
          (lesson) => lesson.is_full_screen === true
        );
  
        // Update the full-screen state based on the remaining lessons
        if (fullScreenLesson) {
          setIsFullScreen(true);
          setFullScreenContent(fullScreenLesson);
        } else {
          setIsFullScreen(false);
          setFullScreenContent(null);
        }
  
        return updatedLessons; // Return the updated lesson info
      });
    },
    [setLessonInfo, setIsFullScreen, setFullScreenContent]
  );

  
  useEffect(() => {
    const fullScreenLesson = lessonInfo.find(
      (lesson) => lesson.is_full_screen === true
    );

    if (fullScreenLesson) {
      setIsFullScreen(true);
      setFullScreenContent(fullScreenLesson);
    } else {
      setIsFullScreen(false);
      setFullScreenContent(null);
    }
  }, [lessonInfo]); 
  
  // Log the current state of isFullScreen whenever it changes
  // useEffect(() => {
  //   console.log("lessonCompletionData:", lessonCompletionData);
  // }, [lessonCompletionData]); // Dependency array triggers whenever isFullScreen updates

  

  const markLessonAsComplete = (lessonId) => {
    let shouldUnlockNextLesson = false;
    
    setSortedLessonData((prevData) => {
      const updatedData = prevData.map((chapter, chapterIndex, chaptersArray) => {
        const updatedLessons = chapter.lessons.map((lesson, lessonIndex, lessonsArray) => {
          if (lesson.lesson_id == lessonId) {
            shouldUnlockNextLesson = true;
            return {
              ...lesson,
              isCompleted: true,
            };
          }
          
          if (shouldUnlockNextLesson) {
            shouldUnlockNextLesson = false;
            return {
              ...lesson,
              isLocked: false,
            };
          }
          
          return lesson;
        });
        
        const allLessonsCompleted = updatedLessons.every(lesson => lesson.isCompleted);
        
        return {
          ...chapter,
          isCompleted: allLessonsCompleted,
          lessons: updatedLessons,
        };
      });
      
      return updatedData;
    });
    
    
  };

  // const checkAndUpdateCompletion = useCallback(() => {
  //   if (sortedLessonData.length > 0) {
  //     const isAllCompleted = sortedLessonData.every(chapter =>
  //       chapter.lessons && chapter.lessons.every(lesson => lesson.isCompleted)
  //     );
      
  //     if (isAllCompleted) {
  //       // Trigger any completion-related actions here
  //       console.log("All lessons completed!");
  //     }
  //   }
  // }, [sortedLessonData]);

  // Add this effect to monitor completion
  // useEffect(() => {
  //   checkAndUpdateCompletion();
  // }, [sortedLessonData, checkAndUpdateCompletion]);
  
  

  return (
    <LessonContext.Provider
      value={{
        storeLessonIdentifiers,
        lessonIdentifiers,
        isNavigating,
        triggerNavigation,
        lessonInfo,
        setLessonInfo,
        lessonCompletionData,
        updateLessonCompletionData,
        sortedLessonData,
        setSortedLessonData,
        fullScreenContent,
        setFullScreenContent,
        isFullScreen,
        setIsFullScreen,
        handleQuizSubmission,
        handleDisableFullScreen,
        hiddenContent,
        setHiddenContent,
        markLessonAsComplete,
       // checkAndUpdateCompletion,
        addLessonData
      }}
    >
      {children}
    </LessonContext.Provider>
  );
};
