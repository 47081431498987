import { Box, Typography } from "@mui/material";
import React from "react";
import messageNotification from "assets/images/message-notification-square.svg";
import chevronup from "assets/images/chevron-up.svg";
import { Link, useParams } from "react-router-dom";
const CourseChatRoomCard = () => {
  const { slug } = useParams();
  return (
    <Box className="course-forum-card" id="chat-room">
      <Typography component="body2" className="course-forum-card--num">
        02
      </Typography>
      <Box
        component="img"
        src={messageNotification}
        alt="message-notification"
        className="course-forum-card--annotation-image"
      />
      <Typography component="h6" className="course-forum-card--course-forum">
        Course Discussion
      </Typography>
      <Typography
        component="body2"
        className="course-forum-card--course-forum--description"
      >
       Instant messaging system connecting learners in real-time for quick questions and collaborative discussions.
      </Typography>
      <Link to={`/app/chat/${slug}`}>
        <Box className="course-forum-card--visit-forum">
          <Typography
            component="body2"
            className="course-forum-card--visit-forum-text"
          >
            Visit Discussion Room
          </Typography>
          <Box
            className="course-forum-card--visit-forum-icon"
            component="img"
            src={chevronup}
            alt="annotation-alert"
          />
        </Box>
      </Link>
    </Box>
  );
};

export default CourseChatRoomCard;
