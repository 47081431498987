import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Badge,
  Grid,
  AppBar,
  Toolbar,
  Paper,
  Avatar,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@mui/material";
import {
  Send,
  EmojiEmotions,
  AttachFile,
  Delete,
  MoreVert,
  Edit,
} from "@mui/icons-material";
import { io } from "socket.io-client";
import { useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import UserContext from "context/userContext/UserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import { decryptData } from "utilityFunction/encryption&Decryption";
import useGetRequest from "hooks/useGetRequest";
import MessageInput from "./MessageInput";

// Import images
import announcementImg from "assets/chat/announcement.svg";
import resourcesImg from "assets/chat/resource.svg";

const socket = io(API_ENDPOINTS.SOCKET_URL);

function ChatArea() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const { userData } = useContext(UserContext);
  const { slug } = useParams();
  const [newMessage, setNewMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [channels, setChannels] = useState(null);
  const [messageCount, setMessageCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [showResources, setShowResources] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);

  // Menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editText, setEditText] = useState("");
  const boxRef = useRef(null);
  const messagesEndRef = useRef(null);


  // API hooks
  const {
    data: channelsData,
    loading: loadingChannelsData,
    getData: fetchChannelsData,
  } = useGetRequest(API_ENDPOINTS.GET_CHAT_ROOM_CHANNELS);
  console.log(channelsData);

  const {
    data: groupData,
    loading: loadingGroupData,
    getData: fetchGroupData,
  } = useGetRequest(API_ENDPOINTS.GET_CHAT_ROOM_CHANNELS_MESSAGE);
  console.log(groupData);

  const {
    data: groupCountData,
    loading: loadingGroupCountData,
    getData: fetchGroupCountData,
  } = useGetRequest(API_ENDPOINTS.GET_CHAT_ROOM_CHANNELS_MESSAGE_COUNT);

  const {
    data: recentData,
    loading: loadingRecentData,
    getData: fetchRecentData,
  } = useGetRequest(API_ENDPOINTS.GET_CHAT_ROOM_CHANNELS_RECENT_MESSAGE);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
  };

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  const getInitials = (firstName, lastName) => {
    try {
      const decryptedFirst = firstName ? decryptData(firstName) : '';
      const decryptedLast = lastName ? decryptData(lastName) : '';

      if (!decryptedFirst && !decryptedLast) return "U";


      // Store initials in a variable
      const firstInitial = decryptedFirst ? decryptedFirst.charAt(0).toUpperCase() : '';
      const lastInitial = decryptedLast ? decryptedLast.charAt(0).toUpperCase() : '';
      const userInitials = `${firstInitial}${lastInitial}`;

      return userInitials || "U"; // Return U if userInitials is empty

    } catch (error) {
      console.error("Error decrypting name data:", error);
      return "U";
    }
  };


  // Add this helper function near the top of your component
  const safeDecrypt = (encryptedData) => {
    try {
      if (!encryptedData || !encryptedData.ciphertext) {
        return '';
      }
      return decryptData(encryptedData);
    } catch (error) {
      console.error("Error decrypting data:", error);
      return '';
    }
  };

  //  effect to count members when groupData changes
  useEffect(() => {
    if (groupData && groupData.memberData) {
      setMemberCount(groupData.memberData.length);
    }
  }, [groupData]);



  /*
  useEffect(() => {
    if (slug) {  // Add slug check
      fetchChannels();
    }
  }, [slug]); // Add slug dependency
  
  
    // Handlers
    const fetchChannels = async () => {
      try {
        setApiLoading(true);
        await fetchChannelsData(slug);
        setApiLoading(false);
      } catch (error) {
        console.error("Failed to fetch channels:", error);
        setApiLoading(false);
      }
    };
  */


  // Initial data fetch
  useEffect(() => {
    if (!apiLoading) {
      fetchChannels();
      setApiLoading(true);
    }
  }, [apiLoading]);

  useEffect(() => {
    if (channelsData && !loadingChannelsData) {
      setChannels(channelsData);

      // Automatically select the first channel if no channel is selected
      if (channelsData.length > 0 && !selectedChannel) {
        const firstChannel = channelsData[0];
        setSelectedChannel(firstChannel.chatroom_id);
        fetchMessagesCount(firstChannel.chatroom_id);
        fetchMessages(firstChannel.chatroom_id, limit, 0);
      }
    }
  }, [channelsData, loadingChannelsData]);



  // Modify the existing useEffect for message tracking to count unread messages
  useEffect(() => {
    if (groupData && !loadingGroupData && userData?.length > 0) {
      const currentUserId = userData[0]?.user_id;
      const unreadCount = groupData.messageData.filter(msg =>
        msg.sender !== currentUserId && !msg.is_read
      ).length;
      setUnreadMessageCount(unreadCount);

      const groupedMessages = groupData.messageData.map((msg) => {
        const attachments = groupData.attachmentsData.filter(
          (attachment) => attachment.message_id === msg.ch_message_id
        );

        const messageUser = groupData.memberData.find(
          member => member.user_id === msg.sender
        );

        return {
          ...msg,
          attachments,

        };
      });

      setMessages((prevMessages) => [...groupedMessages, ...prevMessages]);
    }
  }, [groupData, loadingGroupData, userData]);


  const groupMessagesByDate = (messages) => {
    const grouped = {};
    messages.forEach(msg => {
      const date = new Date(msg.last_updated_on).toDateString();
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(msg);
    });

    // Sort messages within each date group by last_updated_on in ascending order
    Object.keys(grouped).forEach(date => {
      grouped[date].sort((a, b) =>
        new Date(a.last_updated_on) - new Date(b.last_updated_on)
      );
    });

    // Sort the dates themselves in ascending order (oldest to newest)
    const sortedGroups = {};
    Object.keys(grouped)
      .sort((a, b) => new Date(a) - new Date(b))
      .forEach(date => {
        sortedGroups[date] = grouped[date];
      });

    return sortedGroups;
  };
  useEffect(() => {
    if (channelsData && !loadingChannelsData) {
      setChannels(channelsData);
      const initialUnreadCounts = {};
      channelsData.forEach(channel => {
        initialUnreadCounts[channel.chatroom_id] = 0; // Initialize unread counts for each channel
      });
      setUnreadCounts(initialUnreadCounts);
    }
  }, [channelsData, loadingChannelsData]);



  // Calculate total unread messages across all channels
  const totalUnreadCount = Object.values(unreadCounts).reduce((a, b) => a + b, 0);


  // Socket effects
  useEffect(() => {
    socket.on("receiveMessage", async (newMessage) => {
      if (newMessage.chatroom_id === selectedChannel) {
        try {
          await fetchRecentData(newMessage.ch_message_id);
        } catch (error) {
          console.error("Error fetching message details:", error);
        }
      }
    });

    socket.on("messageDeleted", (deletedMessage) => {
      if (deletedMessage.chatroom_id === selectedChannel) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.ch_message_id === deletedMessage.ch_message_id
              ? {
                ...msg,
                is_deleted: true,
                deleted_for_everyone: deletedMessage.delete_type === 'everyone',
                content: "This message was deleted"
              }
              : msg
          )
        );
      }
    });

    socket.on("messageEdited", (editedMessage) => {
      if (editedMessage.chatroom_id === selectedChannel) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.ch_message_id === editedMessage.ch_message_id
              ? { ...msg, content: editedMessage.content, is_edited: true }
              : msg
          )
        );
      }
    });

    return () => {
      socket.off("receiveMessage");
      socket.off("messageDeleted");
      socket.off("messageEdited");
    };
  }, [selectedChannel, fetchRecentData]);

  // Recent message effect
  useEffect(() => {
    if (!loadingRecentData && recentData) {
      const processedMessage = {
        ...recentData.messageData,
        attachments: recentData.attachmentsData,
      };
      setMessages((prevMessages) => [...prevMessages, processedMessage]);
    }
  }, [loadingRecentData, recentData]);



  // Click outside effect
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handlers
  const fetchChannels = async () => {
    try {
      await fetchChannelsData(slug);
    } catch (error) {
      console.error("Failed to fetch channels:", error);
    }
  };

  // const handleChannelClick = async () => {
  //   if (channels && channels.length > 0) {
  //     const channel = channels[0]; // Take the first channel
  //     setOffset(0);
  //     setMessages([]);
  //     setSelectedChannel(channel);
  //     await fetchMessagesCount(channel.chatroom_id);
  //     fetchMessages(channel.chatroom_id, limit, 0);
  //   }
  // };

  const handleChannelClick = (chatroom_id) => {
    setOffset(10);
    setMessages([]);
    setSelectedChannel(chatroom_id);
    fetchMessagesCount(chatroom_id); // Fetch the count of messages
    fetchMessages(chatroom_id, limit, 0); // Fetch messages with pagination
  };

  const handleToggleResources = () => {
    setShowResources(!showResources);
  };

  const handleLoadMore = () => {
    fetchMessages(selectedChannel, limit, offset);
    setShouldScrollToBottom(false);
    // if (!isFetching && offset < messageCount) {
    // }
  };

  const handleChannelSelect = async (channel) => {
    setSelectedChannel(channel); // Set the selected channel
    setMessages([]); // Clear messages when switching channels
    await fetchMessages(channel.chatroom_id); // Fetch messages for the selected channel
  };

  const fetchMessages = async (channelId, limit, currentOffset) => {
    try {
      setIsFetching(true);
      const nextOffset = currentOffset + limit;
      const apiUrl = `${channelId}?limit=${limit}&offset=${currentOffset}`;
      await fetchGroupData(apiUrl);
      setOffset(nextOffset);
      setIsFetching(false);
    } catch (error) {
      console.error("Error fetching messages:", error);
      setIsFetching(false);
    }
  };

  const fetchMessagesCount = async (channelId) => {
    try {
      await fetchGroupCountData(channelId);
    } catch (error) {
      console.error("Error fetching messages count:", error);
    }
  };

  const handleDeleteMessage = (message, deleteType) => {
    if (selectedChannel) {
      socket.emit(
        "deleteMessage",
        {
          ch_message_id: message.ch_message_id,
          chatroom_id: selectedChannel,
          delete_type: deleteType,
          sender_id: userData[0].user_id
        },
        (response) => {
          if (response === "Message deleted") {
            // Update messages to show soft delete state
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.ch_message_id === message.ch_message_id
                  ? {
                    ...msg,
                    is_deleted: true,
                    deleted_for_everyone: deleteType === 'everyone',
                    content: "This message was deleted"
                  }
                  : msg
              )
            );
            console.log("Message successfully deleted");
          } else {
            console.error("Failed to delete message:", response);
          }
        }
      );
    }
  };

  const handleMenuClick = (event, messageId) => {
    setAnchorEl(event.currentTarget);
    setSelectedMessageId(messageId);
  };



  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMessageId(null);
  };

  const handleEditClick = (msg) => {
    setEditingMessageId(msg.ch_message_id);
    setEditText(msg.content);
    handleMenuClose();
  };

  const handleDeleteClick = (msg) => {
    setMessageToDelete(msg);
    setDeleteModalOpen(true);
    handleChatMenuClose();
  };

  const DeleteConfirmationModal = () => {
    const handleClose = () => {
      setDeleteModalOpen(false);
      setMessageToDelete(null);
    };

    const handleDeleteForMe = () => {
      handleDeleteMessage(messageToDelete, 'me');
      handleClose();
    };

    const handleDeleteForEveryone = () => {
      handleDeleteMessage(messageToDelete, 'everyone');
      handleClose();
    };

    return (
      <Dialog
        open={deleteModalOpen}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description"
            style={{ fontFamily: 'Gilroy Regular' }}
          >
            Are you sure you want to delete this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}
            style={{ color: 'grey', fontFamily: 'Gilroy SemiBold' }}
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteForEveryone}
            style={{ color: 'red', fontFamily: 'Gilroy SemiBold' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };



  const handleSendMessage = (message, file) => {
    if (selectedChannel) {
      socket.emit("sendMessage", {
        content: message,
        chatroom_id: selectedChannel,
        sender: userData[0].user_id,
        message_type: file ? "File" : "Text",
        file_name: file ? file.name : null,
        file_type: file ? file.type : null,
        file_data: file ? file.data : null,
      });
    }
  };

  const handleEditChange = (e) => {
    setEditText(e.target.value);
  };

  const handleEditSubmit = (messageId, e) => {
    // Prevent default if event is passed
    if (e) {
      e.preventDefault();
    }

    if (editText.trim()) {
      socket.emit(
        "editMessage",
        {
          ch_message_id: messageId,
          chatroom_id: selectedChannel,
          content: editText,
        },
        (response) => {
          if (response === "Message edited") {
            console.log("Message successfully edited on server");
            // Update the message in the local state
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.ch_message_id === messageId
                  ? { ...msg, content: editText, is_edited: true }
                  : msg
              )
            );
          } else {
            console.error("Failed to edit message:", response);
          }
        }
      );
    }
    setEditingMessageId(null);
    setEditText("");
  };

  const handleEditCancel = () => {
    setEditingMessageId(null);
    setEditText("");
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setEditingMessageId(null);
      setEditText("");
    }
  };


  const [chatMenuAnchorEl, setChatMenuAnchorEl] = useState(null);

  // Function to handle the chat message menu click
  const handleChatMenuClick = (event, messageId) => {
    setChatMenuAnchorEl(event.currentTarget);
    setSelectedMessageId(messageId);
  };

  const handleChatMenuClose = () => {
    setChatMenuAnchorEl(null);
    setSelectedMessageId(null);
  };



  const groupedMessages = groupMessagesByDate(messages);
  // Scroll to the bottom when messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [groupedMessages]);

  return (
    <Grid container sx={{ height: "100%", width: "100%", display: "flex" }}>
      <Grid
        item
        xs={3}
        sx={{
          bgcolor: 'rgba(245, 250, 255, 1)',

          display: "flex",
          flexDirection: "column",
          height: "100vh",
          position: "relative",
          overflowY: "auto",
          alignItems: "center",
        }}
      >
        <Box sx={{
          width: "100%", p: 3, overflowY: "auto", flex: 1, alignItems: "center", "& .channel-list-container": {
            gap: 0,
          }
        }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              ml: 2,
              mt: 1,
              fontFamily: "Gilroy SemiBold",
              fontSize: "25px",
              fontWeight: 400,
              lineHeight: "50px",
              textAlign: "left",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
              color: "var(--secondary_color, rgba(43, 61, 79, 1))",
            }}
          >
            Channels
          </Typography>
          <List sx={{ width: "100%", p: 0, className: "channel-list-container" }}   >
            {/* Announcements Channel */}
            {/* <ListItem
              button
              selected={selectedChannel?.name?.toLowerCase().includes('announcement')}
              sx={{
                mb: 0, // Removed margin between list items
                px: 2, // Added horizontal padding
                py: 1, // Added vertical padding for tighter spacing 
                m: 0,
                mb: 0,
              }}
            >
              <Box
                component="img"
                src={announcementImg}
                alt="Announcements"
                sx={{ width: "25px", height: "19px", mr: 2, fontFamily: "Gilroy-SemiBold" }}
              />
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    sx={{
                      fontFamily: "Gilroy SemiBold",
                      fontSize: "18px",
                      fontWeight: 400,

                      textAlign: "left",
                      textUnderlinePosition: "from-font",
                      textDecorationSkipInk: "none",
                      color: "var(--secondary_color, rgba(43, 61, 79, 1))"

                    }}
                  >
                    Announcements
                  </Typography>
                }
              />
              <Badge
                badgeContent={0}
                sx={{
                  "& .MuiBadge-badge": {
                    bgcolor: "rgba(233, 233, 233, 1)",
                    color: "rgba(43, 61, 79, 1)",
                    borderRadius: "2px",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    right: "-4px",
                    fontFamily: "Gilroy Medium",
                    fontWeight: 400,
                  },
                }}
              />
            </ListItem> */}

            {/* Selected Channel Name */}
            {/* <ListItem
              button
              // selected={true}
              selected={selectedChannel?.name?.toLowerCase().includes('resources')}

              sx={{
                px: 2, // Horizontal padding
                py: 1, // Vertical padding
                m: 0,  // Remove margins completely
              }}
            >
              <Box
                component="img"
                src={resourcesImg}
                alt="Resources"
                sx={{ width: "19px", height: "19px", mr: 2 }}
              />
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    sx={{
                      fontFamily: "Gilroy Medium",
                      fontSize: "18px",
                      fontWeight: 400,
                      lineHeight: "50px",
                      textAlign: "left",
                      textUnderlinePosition: "from-font",
                      textDecorationSkipInk: "none",
                    }}
                  >
                    {channels ? channels.name : 'Loading...'}
                  </Typography>
                }
              />
            </ListItem> */}
            {channels && channels.length > 0 ? (
              channels.map((channel) => (
                <ListItem key={channel.chatroom_id}
                  button
                  onClick={() => handleChannelClick(channel.chatroom_id)}
                  selected={selectedChannel === channel.chatroom_id} >
                  <Box
                    component="img"
                    src={resourcesImg}
                    alt="Resources"
                    sx={{ width: "19px", height: "19px", mr: 2 }}

                  />
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        sx={{
                          fontFamily: "Gilroy Medium",
                          fontSize: "18px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          textAlign: "left",
                          textUnderlinePosition: "from-font",
                          textDecorationSkipInk: "none",
                        }}
                      >
                        {channel.name}
                      </Typography>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <ListItem >
                <ListItemText
                  primary={
                    <Typography
                      component="span"
                      sx={{
                        fontFamily: "Gilroy Medium",
                        fontSize: "18px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                      }}
                    >
                      No Discussion Rooms Found
                    </Typography>
                  }
                />
              </ListItem>
            )}
          </List>
        </Box>

        {/* 
        <Box sx={{ mt: 2, borderTop: '1px solid #E0E0E0', pt: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{
                pl: 2,
                mb: 1,
                color: 'text.secondary',
                fontFamily: "Gilroy SemiBold",
                ml: 1,
                textAlign: "left",
              }}
            >
              All Channels
            </Typography>
            {loadingChannelsData ? (
              <ListItem>
                <Typography>Loading channels...</Typography>
              </ListItem>
            ) : (
              channels?.map((channel) => (
                <ListItem
                  button
                  key={channel.chatroom_id}
                  onClick={() => handleChannelSelect(channel)}
                  selected={selectedChannel?.chatroom_id === channel.chatroom_id}
                  sx={{
                    pl: 3, 
                    '&.Mui-selected': {
                      bgcolor: 'rgba(0, 0, 0, 0.04)',
                      fontFamily: "Gilroy SemiBold",
                      ml: 1,
                      textAlign: "left",

                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        sx={{
                          fontFamily: "Gilroy Medium",
                          color: selectedChannel?.chatroom_id === channel.chatroom_id
                            ? 'primary.main'
                            : 'text.primary',
                        }}
                      >
                        {channel.name}
                      </Typography>
                    }
                  />
                  {unreadCounts[channel.chatroom_id] > 0 && (
                    <Badge
                      badgeContent={unreadCounts[channel.chatroom_id]}
                      sx={{
                        '& .MuiBadge-badge': {
                          bgcolor: 'primary.main',
                          color : 'white',
                        },
                      }}
                    />
                  )}
                </ListItem>
              ))
            )}
          </Box> 
        </List>
      </Box> */}

        <Box sx={{
          p: 7,
          whiteSpace: 'nowrap',

        }}>
          <ListItem
            button
            onClick={() => navigate(`/app/course/${slug}/learn`)}
            sx={{


            }}
          >
            <Typography
              sx={{
                fontFamily: "Gilroy Regular",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "25px",
                textAlign: "left",
                textUnderlinePosition: "from-font",
                textDecorationSkipInk: "none",
                color: "var(--secondary_color, rgba(43, 61, 79, 1))",
                //shift the text to the left
                ml: -2
              }}
            >
              {"<"} Back to Course Dashboard
            </Typography>
          </ListItem>
        </Box>
      </Grid>
      {/* Main Content */}
      <Grid
        item
        xs={9}
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          display: "flex",
          flexDirection: "column",
          height: "100vh"
        }}
      >
        <AppBar
          position="static"
          elevation={0}
          sx={{
            bgcolor: "#FFFFFF",
            color: "text.primary",
            borderBottom: "1px solid rgba(161, 161, 161, 0.29)",
            p: 0.5
          }}
        >
          <Toolbar>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Box display="flex" alignItems="center">
                {selectedChannel && (
                  <>
                    <Box
                      component="img"
                      src={selectedChannel.name ? announcementImg : announcementImg}
                      alt={selectedChannel.name}
                      sx={{ width: "26px", height: "26px", mr: 2 }}
                    />
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Gilroy Medium",
                          fontSize: "20px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          textAlign: "left",
                          textUnderlinePosition: "from-font",
                          textDecorationSkipInk: "none",
                        }}
                      >
                        {channels.find(channel => channel.chatroom_id === selectedChannel)?.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ fontFamily: "Gilroy Light", fontSize: "14px" }}>
                        {memberCount} members
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              {/* <Box>
                <Typography
                  onClick={handleMenuClick}
                  sx={{
                    cursor: "pointer",
                    fontSize: "30px",
                    userSelect: "none",
                  }}
                >
                  ...
                </Typography>
                <Menu
                  id="menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem>Action 1</MenuItem>
                  <MenuItem>Action 2</MenuItem>
                  <MenuItem>Action 3</MenuItem>
                </Menu>
              </Box> */}
            </Box>
          </Toolbar>
        </AppBar>

        {/* Messages Area */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            bgcolor: "rgba(255, 255, 255, 1)",
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            minHeight: 0,
            fontFamily: "Gilroy Regular"
          }}
        >
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
            {Object.entries(groupedMessages).map(([date, msgs], index) => (
              <Box key={date}>
                {index === 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 2, // Margin top for spacing
                      mb: 2, // Margin bottom for spacing
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleLoadMore} // Function to load more messages
                      sx={{
                        fontFamily: 'Gilroy Regular',
                        fontWeight: 500,
                        textTransform: 'none',
                        borderColor: 'rgba(43, 61, 79, 0.5)',
                        color: 'rgba(43, 61, 79, 0.8)',
                        '&:hover': {
                          borderColor: 'rgba(43, 61, 79, 1)',
                          backgroundColor: 'rgba(43, 61, 79, 0.05)',
                        },
                      }}
                    >
                      Load More Messages
                    </Button>
                  </Box>
                )}
                {/* Date Header */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 3,
                    mt: 3
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      px: 3,
                      py: 1,
                      bgcolor: 'rgba(255, 255, 255)',
                      borderRadius: '8px',
                      border: '1px solid rgba(43, 61, 79, 0.09)'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        color: "rgba(43, 61, 79, 0.51)",
                        fontFamily: "Gilroy Regular",
                        fontSynthesisWeight: 400,
                        fontSize: "14px",

                      }}
                    >
                      {formatDate(msgs[0].last_updated_on)}
                    </Typography>
                  </Paper>
                </Box>

                {/* Messages */}
                {msgs.map((msg) => (
                  <Box
                    key={msg.ch_message_id}
                    sx={{
                      display: "flex",
                      justifyContent: msg.sender === userData[0]?.user_id ? "flex-end" : "flex-start",
                      alignItems: "flex-end",
                      mb: 2,
                      minWidth: "100px",

                      fontFamily: "Gilroy Regular"
                    }}
                  >
                    {/* Avatar for both sender and receiver */}
                    {(msg.sender === userData[0]?.user_id || msg.sender !== userData[0]?.user_id) && (
                      <Avatar
                        sx={{
                          width: 40,
                          height: 40,
                          mr: msg.sender !== userData[0]?.user_id ? 2 : 0, // Margin for receiver
                          ml: msg.sender === userData[0]?.user_id ? 2 : 0, // Margin for sender
                          bgcolor: "#2B3D4F",
                          color: "#fff",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Gilroy Regular",
                          backgroundImage: msg.User?.profile_pic
                            ? `url(${API_ENDPOINTS.PROFILE_PHOTO}/${encodeURIComponent(msg.User.profile_pic)})`
                            : "none",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        {!msg.User?.profile_pic && getInitials(msg.User?.first_name, msg.User?.last_name)}
                      </Avatar>
                    )}

                    <Box sx={{
                      maxWidth: "70%",
                      minWidth: "150px",
                      wordBreak: "break-word"
                    }}>
                      {msg.sender !== userData[0]?.user_id && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            mb: 1,
                            fontFamily: "Gilroy SemiBold"
                          }}
                        >
                          {msg.User ? `${safeDecrypt(msg.User.first_name)} ${safeDecrypt(msg.User.last_name)}` : "U"}
                        </Typography>
                      )}

                      <Paper
                        elevation={0}
                        sx={{
                          p: 2,
                          bgcolor: msg.sender === userData[0]?.user_id   // Change the color based on the sender of the message
                            ? "rgba(246, 248, 250, 1)"
                            : "rgba(246, 248, 250, 1)",
                          color: msg.is_deleted ? "text.secondary" : "black",
                          borderRadius: 2,
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                          position: "relative",
                          minHeight: "auto",
                          overflowWrap: "break-word",
                        }}
                      >
                        {msg.is_deleted ? (
                          <Typography
                            variant="body1"
                            sx={{
                              fontStyle: 'italic',
                              color: 'text.secondary'
                            }}
                          >
                            ⊘  This message was deleted.
                          </Typography>
                        ) : (
                          <>
                            {editingMessageId === msg.ch_message_id ? (
                              <Box
                                component="form"
                                onSubmit={(e) => handleEditSubmit(msg.ch_message_id, e)}
                                sx={{ position: 'relative' }}
                              >
                                <TextField
                                  value={editText}
                                  onChange={handleEditChange}
                                  onKeyDown={(e) => {
                                    if (e.key === "Escape") {
                                      handleEditCancel();
                                    }
                                  }}
                                  fullWidth
                                  autoFocus
                                  multiline
                                  size="small"
                                  sx={{ mb: 1 }}
                                />
                                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                                  <IconButton
                                    size="small"
                                    onClick={handleEditCancel}
                                    sx={{
                                      color: 'text.secondary',
                                      border: '1px solid rgba(43, 61, 79, 0.61)', // Add border
                                      borderRadius: 1, // Optional: to round the border
                                      ml: 1, // Optional: add some margin
                                      fontFamily: "Gilroy Medium",
                                    }}
                                  >
                                    Cancel
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    type="submit"

                                    sx={{
                                      color: 'rgba(255, 255, 255, 1)',
                                      border: '1px solid rgba(43, 61, 79, 0.61)', // Add border
                                      borderRadius: 1, // Optional: to round the border
                                      ml: 1, // Optional: add some 
                                      backgroundColor: 'rgba(43, 61, 79, 1)',
                                      fontFamily: "Gilroy Medium",
                                    }}
                                  >
                                    Save
                                  </IconButton>
                                </Box>
                              </Box>
                            ) : (
                              <>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mb: msg.attachments?.length > 0 ? 2 : 3,
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-word",
                                    fontFamily: "Gilroy Regular",
                                    fontWeight: 400,
                                  }}
                                >
                                  {msg.content}
                                </Typography>

                                {/* Attachments */}
                                {msg.attachments?.map((attachment, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      mt: 1,
                                      mb: 3,
                                      maxWidth: "300px",
                                      '& img': {
                                        maxWidth: "100%",
                                        maxHeight: "200px",
                                        objectFit: "contain",
                                        borderRadius: 1
                                      }
                                    }}
                                  >
                                    {attachment.file_type.startsWith("image/") ? (<img
                                      src={`${API_ENDPOINTS.GET_CHAT_ROOM_FILE}/${attachment.file_name}`}
                                      alt={attachment.file_name}
                                    />
                                    ) : (
                                      <a
                                        href={`${API_ENDPOINTS.GET_CHAT_ROOM_FILE}/${attachment.file_name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View File
                                      </a>
                                    )}
                                  </Box>
                                ))}


                                {/* Time and Edit Status */}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    position: 'absolute',
                                    bottom: 4,
                                    right: 8,
                                    color: 'text.secondary',
                                    fontSize: '0.7rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    fontFamily: "Gilroy Regular",
                                    fontWeight: 400,
                                  }}
                                >
                                  {msg.is_edited && <span>(edited)</span>}
                                  {formatTime(msg.last_updated_on)}
                                </Typography>
                              </>
                            )}

                            {msg.sender === userData[0]?.user_id && !editingMessageId && (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={(e) => handleChatMenuClick(e, msg.ch_message_id)}
                                  sx={{
                                    position: "absolute",
                                    top: 4,
                                    right: 4,
                                    color: "inherit",
                                    color: "gray",

                                  }}
                                >
                                  <MoreVert
                                    sx={{
                                      fontSize: "18px", // Adjust this to make the dots smaller (default is 24px)
                                    }}
                                  />
                                </IconButton>
                                <Menu
                                  anchorEl={chatMenuAnchorEl}
                                  open={Boolean(chatMenuAnchorEl) && selectedMessageId === msg.ch_message_id}
                                  onClose={handleChatMenuClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem onClick={() => handleEditClick(msg)}
                                    sx={{
                                      borderBottom: "0.3px solid rgba(161, 161, 161, 0.29)", // Border between Edit and Delete
                                    }}

                                  >
                                    <Edit
                                      fontSize="small"
                                      sx={{
                                        mr: 1,
                                      }}
                                    />
                                    <span
                                      style={{
                                        fontFamily: 'Gilroy Regular', // Custom font family
                                        fontWeight: 400,             // Custom font weight
                                      }}
                                    >
                                      Edit
                                    </span>
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() => {
                                      handleDeleteClick(msg)
                                    }}
                                  >
                                    <Delete fontSize="small" sx={{ mr: 1, color: "red" }} />
                                    <span
                                      style={{
                                        fontFamily: 'Gilroy Regular', // Custom font family
                                        fontWeight: 400,             // Custom font weight
                                        color: 'rgba(252, 99, 102, 1)',

                                      }}
                                    >
                                      Delete
                                    </span>
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </>
                        )}

                      </Paper>
                    </Box>
                  </Box>
                ))}
              </Box>
            ))}
            <div ref={messagesEndRef} />
          </Box>


        </Box>

        {/* Message Input */}
        {selectedChannel && (
          <Box
            sx={{
              p: 2,
              bgcolor: "rgba(255, 255, 255, 1)",

              position: "sticky",
              bottom: 0,
              width: "100%"
            }}
          >
            <MessageInput
              user_id={userData[0].user_id}
              chatroom_id={selectedChannel}
            // onSendMessage={handleSendMessage}
            />
          </Box>
        )}
      </Grid>
      <DeleteConfirmationModal />
    </Grid>
  );
}
export default ChatArea;