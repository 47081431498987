import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseViewContext from "context/courseViewContext/CourseViewContext";
import LoadingContext from "context/loadingContext/LoadingContext";
import { formatDuration } from "utilityFunction/convertMinToHours";
import { API_ENDPOINTS } from "config/ApiConfig";
import { Box, Button, Card, Skeleton, Typography } from "@mui/material";
import barchart from "assets/icons/bar-chart.svg";
import clock from "assets/icons/clock2.svg";
import yellowclock from "assets/icons/clock.svg";
import blueStar from "assets/icons/star-blue.svg";
import chevronRight from "assets/icons/chevron-right.svg";
import { handleDownload } from "utilityFunction/donwloadFile";
import UserContext from "context/userContext/UserContext";

const DisciplineInSchoolCard = ({ certificateUrl }) => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [courseDetails, setCourseDetails] = useState({});
  const [thumbnailURL, setThumbnailURL] = useState("");
  const { courseData, transformedData, transformData, totalPercentage } = useContext(CourseViewContext);
  const [activeLessonDetails, setActiveLessonDetails] = useState({});
  const [storeSlug, setStoreSlug] = useState("");
  const { isAppLoading, setIsAppLoading } = useContext(LoadingContext);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState("");
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (slug) {
      setStoreSlug(slug);
    }
  }, [slug]);

  useEffect(() => {
    // console.log('transformedData  -- > ', transformedData)
    // console.log('courseData  -- > ', courseData)
    // console.log('activeLessonDetails  -- > ', activeLessonDetails)
    // console.log('transformData  -- > ', transformData)
    if (courseData && transformedData) {
      setActiveLessonDetails(transformedData);
      setCourseDetails(courseData);
      setThumbnailURL(
        `${API_ENDPOINTS.GET_IMAGE}/${courseData.course_thumbnail}`
      );
    }
  }, [courseData, transformedData]);

  useEffect(() => {
    if (totalPercentage) {
      // Update state with fetched data
      if (typeof totalPercentage === 'number') {
        setPercentage(totalPercentage.toFixed(0));
      }
    }
  }, [totalPercentage]);

  useEffect(() => {
    if (courseDetails.course_name) {
      setLoading(false);
    }
  }, [courseDetails])

  const handleClick = () => {
    const lessonSlug = activeLessonDetails?.activeLesson?.lesson_slug;
    if (storeSlug && lessonSlug) {
      navigate(`/app/course/${storeSlug}/learn/${lessonSlug}`);
    }
  };


  if (loading) {
    return (
      <Box className="DisciplineInSchoolCard__skeleton" sx={{ width: '100%', height: 180, padding: 2, borderRadius: '18px' }}>
        <Skeleton variant="rectangular" height="100%" sx={{ bgcolor: 'grey.300', borderRadius: '18px' }} />
      </Box>
    );
  }


  return (
    courseDetails && activeLessonDetails && (
      <Card onClick={handleClick} className="DisciplineInSchoolCard">
        <Box className="DisciplineInSchoolCard__wrapper">
          <Box className="DisciplineInSchoolCard-info-wrapper">
            <Typography
              className="DisciplineInSchoolCard-info-wrapper__title"
              variant="h6"
            >
              {courseDetails.course_name || ""}
            </Typography>
            <Box className="DisciplineInSchoolCard-info-wrapper__details">
              <Box className="DisciplineInSchoolCard-info-wrapper__details-item">
                <Box
                  component="img"
                  src={blueStar}
                  className="DisciplineInSchoolCard-info-wrapper__details-icon"
                  id="star"
                  alt="rating"
                />
                <Typography
                  className="DisciplineInSchoolCard-info-wrapper__details-text"
                  variant="body2"
                >
                  {courseDetails?.courseStat?.avg_review ?? "0"}
                </Typography>
              </Box>

              <Box className="DisciplineInSchoolCard-info-wrapper__details-item">
                <Box
                  component="img"
                  src={barchart}
                  className="DisciplineInSchoolCard-info-wrapper__details-icon"
                  id="barchart"
                  alt="barchart"
                />
                <Typography
                  className="DisciplineInSchoolCard-info-wrapper__details-text"
                  variant="body2"
                >
                  {courseDetails?.course_difficulty || ""}
                </Typography>
              </Box>

              <Box className="DisciplineInSchoolCard-info-wrapper__details-item">
                <Box
                  component="img"
                  src={clock}
                  className="DisciplineInSchoolCard-info-wrapper__details-icon"
                  alt="Duration"
                  id="clock"
                />
                <Typography
                  className="DisciplineInSchoolCard-info-wrapper__details-text"
                  variant="body2"
                >
                  {courseDetails?.courseStat?.avg_completion_time
                    ? formatDuration(courseDetails?.courseStat?.avg_completion_time)
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Button
              aria-readonly
              sx={{ borderRadius: "30px" }}
              variant="contained"
              className="DisciplineInSchoolCard-info-wrapper__in-progress-button"
            >
              {courseDetails?.status || ""}
            </Button>
          </Box>
        </Box>
        <Box
          className="DisciplineInSchoolCard__image"
          component="img"
          src={thumbnailURL || ""}
          alt="course image"
        />
        <Box className="DisciplineInSchoolCard__footer">
          <Box className="DisciplineInSchoolCard__footer-content">
            <Box className="DisciplineInSchoolCard__footer-content--details">
              <Typography
                className="DisciplineInSchoolCard__footer-content--details-text"
                variant="h6"
              >
                {activeLessonDetails?.activeChapter?.chapter_name || ""}
              </Typography>
              <Box
                component="img"
                src={yellowclock}
                className="DisciplineInSchoolCard__footer-content--details-icon"
                id="clock"
                alt="duration"
              />
              <Typography
                className="DisciplineInSchoolCard__footer-content--details-time-remaining"
                variant="body2"
              >
                {activeLessonDetails?.timeOfIncompleteLessons
                  ? `${formatDuration(activeLessonDetails?.timeOfIncompleteLessons)} Remaining`
                  : ""}
              </Typography>
            </Box>
            {percentage === '100' ? (
              userData[0].is_institution_user === 1 ? (
                <Typography
                  className="DisciplineInSchoolCard__footer-content--text-link"

                >
                  Congratulations!
                </Typography>
              ) : (
                <Typography
                  className="DisciplineInSchoolCard__footer-content--text-link"
                  onClick={() => handleDownload(certificateUrl)}
                >
                  Download Certificate
                </Typography>
              )
            ) : (
              activeLessonDetails?.activeLesson?.lesson_slug ? (
                courseDetails?.status === 'enrolled' ? (
                  <Box
                    sx={{ display: "flex", padding: "0", cursor: "pointer" }}
                    onClick={handleClick}
                  >
                    <Typography className="DisciplineInSchoolCard__footer-content--text-link">
                      Start Learning
                    </Typography>
                    <Box
                      component="img"
                      src={chevronRight}
                      alt="chevron-right"
                      className="DisciplineInSchoolCard__footer-content--chevron-icon"
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{ display: "flex", padding: "0", cursor: "pointer" }}
                    onClick={handleClick}
                  >
                    <Typography className="DisciplineInSchoolCard__footer-content--text-link">
                      Continue Learning
                    </Typography>
                    <Box
                      component="img"
                      src={chevronRight}
                      alt="chevron-right"
                      className="DisciplineInSchoolCard__footer-content--chevron-icon"
                    />
                  </Box>
                )
              ) : ''
            )}



          </Box>
        </Box>
      </Card>
    )
  );
}

export default DisciplineInSchoolCard;
