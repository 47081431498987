import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingContext from "context/loadingContext/LoadingContext";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import { Box, Grid } from "@mui/material";
import CoursePriceCard from "components/module/app/courseDetails/CoursePriceCard";
import VideoPlayer from "components/common/videoPlayer/VideoPlayer";
import CourseDetails from "components/module/app/courseDetails/CourseDetails";
import CourseContent from "components/module/app/courseDetails/CourseContent";
import useGetRequest from "hooks/useGetRequest";
import CourseDetailsContext from "context/courseDetailsContext/CourseDetailsContext";
import VideoPlayerPublic from "components/common/videoPlayer/VideoPlayerPublic";
import VideoPlayerBunnyNet from "components/common/videoPlayer/VideoPlayerBunnyNet";

const CourseDetailsPage = () => {
  //store the slug in a var
  const { slug } = useParams();
  //state to store the slug
  const [storeSlug, setStoreSlug] = useState("");
  //state to store the video url
  const [videoURL, setVideoURL] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);

  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);
  const { isAppLoading } = useContext(LoadingContext);
  //context for course details
  const {
    // loadingCourseInformation,
    courseInformation,
    addDataTOCourseContext,
    clearDataFromCourseContext,
    // setLoadingCourseInformation
  } = useContext(CourseDetailsContext);

  const [courseDetails, setCourseDetails] = useState(null);
  const [lessonDetails, setLessonDetails] = useState(null);
  const [hostType, setHostType] = useState("");

  //custom get req hook
  const {
    data: courseData,
    loading: loadingCourseData,
    error: errorLoadingCourseData,
    getData: courseDataPayload,
  } = useGetRequest(API_ENDPOINTS.COURSES_FOR_USER);

  //store the slub in a usestate
  useEffect(() => {
    if (slug) {
      setStoreSlug(slug);
      //add slug to course details context, this slug is used for redirecting to the course-details page after payment failure
      addDataTOCourseContext(slug);
    }
  }, [slug]);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (storeSlug) {
        try {
          await courseDataPayload(storeSlug);
        } catch (err) {
          if (err.response) {
            notifyUser(
              "Error",
              "An error occurred while fetching course details.",
              "error"
            );
          } else if (err.request) {
            // The request was made but no response was received
            notifyUser(
              "Network Error",
              "Network error, please try again.",
              "warning"
            );
          } else {
            // An error occurred in setting up the request
            notifyUser(
              "Unexpected Error",
              `Unexpected error: ${err.message}`,
              "error"
            );
          }
        }
      }
    };
    fetchCourseDetails();
  }, [isAppLoading, storeSlug]);

  useEffect(() => {
    if (!loadingCourseData) {
      if (errorLoadingCourseData) {
        console.log(
          "error while fetching the course details of the course",
          errorLoadingCourseData
        );
      } else if (courseData) {
        //set the value to the local state
        setCourseDetails(courseData.courseDetails);
        setLessonDetails(courseData.courseStat);
        //set the course context also
        addDataTOCourseContext(courseData.courseDetails);
        // setLoadingCourseInformation(true);
        // Set the thumbnail URL
        if (
          courseData.courseDetails.MasterVideo &&
          courseData.courseDetails.MasterImage &&
          courseData.courseDetails.MasterVideo.name &&
          courseData.courseDetails.MasterImage.name
        ) {
          const hostType = String(courseData.courseDetails.MasterVideo.hosting_type);
          setHostType(hostType);

          if (hostType === "1") {
            // Video hosted on your server
            setVideoURL(`${API_ENDPOINTS.GET_VIDEO_URL}/${courseData.courseDetails.MasterVideo.name}`);
          } else if (hostType === "2") {
            // YouTube video, assuming videoFileName contains the YouTube video ID
            setVideoURL(`https://www.youtube.com/watch?v=${courseData.courseDetails.MasterVideo.name}`);
          }

          //this should be chnaged to the thumbnai api endpoint
          setThumbnailURL(
            `${API_ENDPOINTS.GET_IMAGE}/${courseData.courseDetails.MasterImage.name}`
          );
        } else {
          setThumbnailURL("");
          setVideoURL("");
        }
      } else {
        // console.log("error with the get request data from course-details page");
      }
    }
  }, [courseData, loadingCourseData]);

  return (
    courseDetails && (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={8}>
          <Box className="player-wrapper-course-view">
            {hostType === "1" && (
              <VideoPlayerPublic
                videoUrl={videoURL}
                thumbnailUrl={thumbnailURL}
              />
            )}
            {hostType === "2" && (
              <VideoPlayer
                videoUrl={videoURL}
                thumbnailUrl={thumbnailURL}
              />
            )}
            {hostType === "3" && (
              <VideoPlayerBunnyNet
                videoUrl={videoURL}
                thumbnailUrl={thumbnailURL}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: {
              xs: "static", // For extra-small screens (mobile)
              sm: "static", // For small screens (tablet)
              md: "sticky", // For medium screens and larger
            },
            top: "61px",
          }}
        >
          <CoursePriceCard courseDetails={courseDetails} lessonDetails={lessonDetails} Slug={storeSlug} />
        </Grid>
        <Grid item xs={12} md={8} sm={12}>
          <CourseDetails
            course_name={courseDetails ? courseDetails.course_name : ""}
            description={courseDetails ? courseDetails.description : ""}
            difficulty={
              courseDetails ? courseDetails.RefCourseDifficulty.name : ""
            }
            duration={courseDetails ? courseDetails.duration : ""}
            lessonCount={lessonDetails ? lessonDetails.total_lessons : ""}
          />
        </Grid>
        <Grid item xs={12} md={8} sm={12}>
          <CourseContent />
        </Grid>
      </Grid>
    )
  );
};

export default CourseDetailsPage;
