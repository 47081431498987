import * as yup from "yup";

// Email regex that's case insensitive
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

// Username regex that's case insensitive
const usernameRegex = /^[a-zA-Z0-9]+$/i;

export const loginFormSchema = yup.object().shape({
  username: yup
    .string()
    .required("Username is required")
    .test("username-or-email", "Invalid Username", function (value) {
      if (!value) return false;

      // Trim the value before validation
      const trimmedValue = value.trim();

      if (emailRegex.test(trimmedValue)) {
        return true;
      }

     
      //checking if it's a valid username
      const hasLetter = /[a-zA-Z]/.test(trimmedValue);
      const hasNumber = /\d/.test(trimmedValue);
      const isValidUsername =
        trimmedValue.length >= 6 &&
        trimmedValue.length <= 35 &&
        usernameRegex.test(trimmedValue) &&
        !/[\W_]/.test(trimmedValue);

      if (isValidUsername) {
        if (!hasLetter) {
          return this.createError({
            message: "Username must contain at least one letter",
          });
        }
        if (!hasNumber) {
          return this.createError({
            message: "Username must contain at least one number",
          });
        }
        return true;
      }

      return false;
    }),
    password: yup
    .string()
    .required("Password is required")
    .min(8, "Password should be at least 8 characters")
    .max(20, "Password should be below 20 characters")
    .matches(/[\W_]/, "Password should contain at least one special character")
    .notOneOf([" "], "Password should not contain spaces"),
});

//create-account form schema

export const createAccountFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .min(1, "First name should be at least 1 character")
    .max(30, "First name should not exceed 30 characters")
    .matches(/^[A-Za-z ]*$/, "Alphabetic characters only")
    // .test("no-trailing-spaces", "No trailing spaces", (value) => {
    //   return value === undefined || value.trim() === value;
    // })
    // .test(
    //   "single-spaces-between-words",
    //   "Single spaces between words",
    //   (value) => {
    //     return value === undefined || /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value);
    //   }
    // )
    .test(
      "not-equal-to-lastName",
      "First name and Last name should not be equal",
      function (value) {
        return value !== this.parent.lastName;
      }
    ),

  lastName: yup
    .string()
    .required("Last name is required")
    .min(1, "Last name should be at least 1 character")
    .max(30, "Last name should not exceed 30 characters")
    .matches(/^[A-Za-z ]*$/, "Alphabetic characters only")
    // .test("no-trailing-spaces", "No trailing spaces", (value) => {
    //   return value === undefined || value.trim() === value;
    // })
    // .test(
    //   "single-spaces-between-words",
    //   "Single spaces between words",
    //   (value) => {
    //     return value === undefined || /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value);
    //   }
    // )
    .test(
      "not-equal-to-firstName",
      "First name and Last name should not be equal",
      function (value) {
        return value !== this.parent.firstName;
      }
    ),

    dateOfBirth: yup
    .date()
    .typeError("Please enter a valid date")
    .required("Date of Birth is required")
    .test("valid-date", "Please enter a valid date", function(value) {
      if (!value) return false;
      
      // Check if the date is valid
      const date = new Date(value);
      if (isNaN(date.getTime())) return false;
      
      // Check if the month is valid (0-11)
      if (date.getMonth() > 11) return false;
      
      // Create a date object and compare with the input
      const inputDay = date.getDate();
      const inputMonth = date.getMonth();
      const inputYear = date.getFullYear();
      
      // Get the last day of the input month
      const lastDay = new Date(inputYear, inputMonth + 1, 0).getDate();
      
      // Check if the day is valid for the given month
      if (inputDay > lastDay) return false;
      
      return true;
    })
    .test(
      "ageRestriction",
      "Students should be at least 6 years old to enroll",
      function (value) {
        if (!value || !this.parent.dateOfBirth) return false;
        
        const today = new Date();
        let age = today.getFullYear() - value.getFullYear();
        const monthDiff = today.getMonth() - value.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < value.getDate())
        ) {
          age--;
        }
        return age >= 6;
      }
    )
    .max(new Date(), "Date of Birth cannot be in the future"),

  email: yup
    .string()
    .required("Email is required")
    .matches(emailRegex, "Enter a valid email")
    .trim(),

  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password should be at least 8 characters")
    .max(20, "Password should not exceed 20 characters")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .notOneOf([" "], "Password should not contain spaces"),

  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

// schema for forgot password

export const forgotPasswordFormSchema = yup.object().shape({
  username: yup
    .string()
    .required("Username or email required")
    .test("username-or-email", "Invalid Username", function (value) {
      if (!value) return false;

      // Trim and normalize the value to lowercase before validation
      const trimmedValue = value.trim() ;

      // checking if it's a valid email
      if (emailRegex.test(trimmedValue)) {
        return true;
      }

 // Check if it's a valid username (case insensitive)
 const hasLetter = /[a-zA-Z]/i.test(trimmedValue);
 const hasNumber = /\d/.test(trimmedValue);
 const isValidUsername =
   trimmedValue.length >= 6 &&
   trimmedValue.length <= 35 &&
   usernameRegex.test(trimmedValue);

 if (isValidUsername) {
   if (!hasLetter) {
     return this.createError({
       message: "Username must contain at least one letter",
     });
   }
   if (!hasNumber) {
     return this.createError({
       message: "Username must contain at least one number",
     });
   }
   return true;
 }

 return false;
}),
});


//reset password schema
export const resetPasswordFormSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Password should be at least 8 characters")
    .max(20, "Password should not exceed 20 characters")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .notOneOf([" "], "Password should not contain spaces"),

  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

//date of birth schema
export const dateOfBirthFormSchema = yup.object().shape({
  dateOfBirth: yup
    .date()
    .typeError("Invalid date")
    .required("Date of Birth is required")
    .test(
      "ageRestriction",
      "Students should be at least 6 years old to enroll",
      function (value) {
        const today = new Date();
        let age = today.getFullYear() - value.getFullYear();
        const monthDiff = today.getMonth() - value.getMonth();

        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < value.getDate())
        ) {
          age--;
        }

        return age >= 6;
      }
    )
    .max(new Date(), "Date of Birth cannot be in the future"),
});