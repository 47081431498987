import React, { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import LessonContext from "context/lessonContext/LessonContext";
import LoadingContext from "context/loadingContext/LoadingContext";
import { useNavigate, useParams } from "react-router-dom";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";

const NavigateChapterBottom = ({ setIsContentLoading }) => {
  const { lesson: currentLessonSlug, chapter: currentChapterSlug } =
    useParams();
  const navigate = useNavigate();
  const { sortedLessonData, lessonCompletionData } = useContext(LessonContext);
  const [sortedLessonInfo, setSortedLessonInfo] = useState([]);
  const [nextLessonSlug, setNextLessonSlug] = useState("");
  const [previousLessonSlug, setPreviousLessonSlug] = useState(""); // State to store previous lesson slug
  const [isLastLesson, setIsLastLesson] = useState(false); // State to check if it's the last lesson
  const { setIsAppLoading } = useContext(LoadingContext);
  const { message, notifyUser } = useContext(NofityUserContext);
  const [allViewed, setAllViewed] = useState(false);

  useEffect(() => {
    // Update `allViewed` whenever `lessonCompletionData` changes
    const isAllViewed = lessonCompletionData.every(item => item.is_viewed);
    setAllViewed(isAllViewed);
  }, [lessonCompletionData]);


  useEffect(() => {
    if (sortedLessonData) {
      setSortedLessonInfo(sortedLessonData);
    }
  }, [sortedLessonData]);

  const calculateLessonSlugs = () => {
    let activeChapter;
    let activeLessonIndex;

    for (const chapter of sortedLessonInfo) {
      const lessonIndex = chapter.lessons.findIndex(
        (lesson) => lesson.lesson_slug === currentLessonSlug
      );
      if (lessonIndex !== -1) {
        activeChapter = chapter;
        activeLessonIndex = lessonIndex;
        break;
      }
    }

    if (!activeChapter || activeLessonIndex === undefined) {
      setNextLessonSlug("");
      setPreviousLessonSlug("");
      setIsLastLesson(false);
      return;
    }

    // Calculate Next Lesson Slug
    if (activeLessonIndex < activeChapter.lessons.length - 1) {
      const nextLesson = activeChapter.lessons[activeLessonIndex + 1];
      if (!nextLesson.isLocked) {
        setNextLessonSlug(nextLesson.lesson_slug);
        setIsLastLesson(false); // Not the last lesson
      }
    } else {
      const nextChapterIndex =
        sortedLessonInfo.findIndex(
          (chap) => chap.chapter_id === activeChapter.chapter_id
        ) + 1;
      if (nextChapterIndex < sortedLessonInfo.length) {
        const nextChapter = sortedLessonInfo[nextChapterIndex];
        const nextLesson = nextChapter.lessons[0];
        if (!nextLesson.isLocked) {
          setNextLessonSlug(nextLesson.lesson_slug);
          setIsLastLesson(false); // Not the last lesson
        }
      } else {
        setIsLastLesson(true); // This is the last lesson in the course
      }
    }

    // Calculate Previous Lesson Slug
    if (activeLessonIndex > 0) {
      const previousLesson = activeChapter.lessons[activeLessonIndex - 1];
      if (!previousLesson.isLocked) {
        setPreviousLessonSlug(previousLesson.lesson_slug);
      }
    } else {
      const previousChapterIndex =
        sortedLessonInfo.findIndex(
          (chap) => chap.chapter_id === activeChapter.chapter_id
        ) - 1;
      if (previousChapterIndex >= 0) {
        const previousChapter = sortedLessonInfo[previousChapterIndex];
        const previousLesson = previousChapter.lessons[previousChapter.lessons.length - 1];
        if (!previousLesson.isLocked) {
          setPreviousLessonSlug(previousLesson.lesson_slug);
        }
      }
    }
  };

  useEffect(() => {
    calculateLessonSlugs();
  }, [sortedLessonInfo, currentLessonSlug]);

  const handleNextClick = () => {
    if (nextLessonSlug && allViewed) {
      setIsAppLoading(true);
      setIsContentLoading(true);
      // console.log("Navigating to Next Lesson:", nextLessonSlug);
      
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: "smooth" });
      
      navigate(`/app/course/${currentChapterSlug}/learn/${nextLessonSlug}`);
      setIsAppLoading(false);
      setIsContentLoading(false);
    } else {
      notifyUser(
        "Almost there!",
        "Please complete the current lesson before moving on to the next one.",
        "warning"
      );
    }
  };

  const handlePreviousClick = () => {
    if (previousLessonSlug) {
      setIsAppLoading(true);
      setIsContentLoading(true);
      // console.log("Navigating to Previous Lesson:", previousLessonSlug);
      
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: "smooth" });
      
      navigate(`/app/course/${currentChapterSlug}/learn/${previousLessonSlug}`);
      setIsAppLoading(false);
      setIsContentLoading(false);
    } else {
      notifyUser(
        "Almost there!",
        "Please complete the current lesson before moving on to the next one.",
        "warning"
      );
    }
  };

  const handleCompleteClick = () => {
    alert("Congratulations! You have completed the course.");
    // Here, you can add any logic for marking the course as completed
  };

  return (
    sortedLessonInfo.length > 0 && (
      <Box className="navigate-chapter-bottom">
        <Button
            className="navigate-chapter__button"
            variant="outlined"
            onClick={handlePreviousClick}
            disabled={!previousLessonSlug} // Disable if no previous lesson
          >
            Previous
          </Button>
        {/* {!isLastLesson && ( */}
          <Button
            id="next"
            className="navigate-chapter__button"
            variant="outlined"
            onClick={handleNextClick}
            disabled={isLastLesson}
            sx={{
              backgroundColor: !nextLessonSlug ? '#f0f0f0' : '',
              color: !nextLessonSlug ? '#f0f0f0' : '',
            }}
          >
            Next
          </Button>
        {/* ) } */}
      </Box>
    )
  );
};

export default NavigateChapterBottom;