import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from 'context/userContext/UserContext';
    
export const ProtectedRoute = ({ element, requiresNonInstitutional = false }) => {
  const { isInstitutionalUser, isLoading } = useContext(UserContext);

  if (isLoading) {
    return null; // Or a loading spinner
  }

  if (requiresNonInstitutional && isInstitutionalUser()) {
    return <Navigate to="/dashboard" replace />;
  }

  return element;
};