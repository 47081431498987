import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Header } from "components/common/header/Header";
import PurchaseHistoryTable from "components/module/app/purchaseHistory/PurchaseHistoryTable";
import useGetRequest from "hooks/useGetRequest";
import LoadingContext from "context/loadingContext/LoadingContext";
import { API_ENDPOINTS } from "config/ApiConfig";

const PurchaseHistoryPage = () => {
  const [groupedCourses, setGroupedCourses] = useState([]);
  const [statusFilter, setStatusFilter] = useState("viewAll");

  const {
    data: paymentHistoryData,
    error: paymentHistoryDataError,
    loading: paymentHistoryDataLoading,
    getData: paymentHistoryDataPayload,
  } = useGetRequest(API_ENDPOINTS.FETCH_PAYMENT_HISTORY);

  const { isAppLoading } = useContext(LoadingContext);

  useEffect(() => {
    paymentHistoryDataPayload();
  }, [isAppLoading]);

  useEffect(() => {
    if (paymentHistoryData) {
      if (paymentHistoryData.success) {
        const courseMap = new Map();
  
        paymentHistoryData.courseDetails.forEach((course) => {
          const courseData = course.courseDetails || {};
          const masterCourse = courseData.MasterCourse || {};
          const paymentStatus = course.payment.payment_status;
          const courseEnrolledStatus = course.courseEnrolledStatus || {};
  
          const paymentStatusCheck = paymentStatus === "completed" ? "Paid" : "Canceled";
          const courseStatus = (courseEnrolledStatus.status || "n/a").toString();
          const isEnrolled = courseStatus === "in-progress" || courseStatus === "enrolled";
  
          const courseItem = {
            id: course.payment.course_id,
            status: paymentStatusCheck,
            course: masterCourse.course_name || "N/A",
            rating: courseData.avg_review || "N/A",
            level: masterCourse.RefCourseDifficulty?.name || "N/A",
            duration: `${courseData.total_lessons || 0} Lessons`,
            progress: Math.min((course.userProgressCount / (courseData.total_lessons || 1)) * 100, 100),
            invoice: `${course.payment.invoice_id || "N/A"}.pdf`,
            showDownload: paymentStatusCheck === "Paid",
            courseStatus: isEnrolled ? "enrolled" : courseStatus,
          };
  
          if (!courseMap.has(courseItem.id) || courseMap.get(courseItem.id).status === "Canceled") {
            courseMap.set(courseItem.id, courseItem);
          }
        });
  
        const coursesGrouped = Array.from(courseMap.values());
        const filteredCourses = statusFilter === "viewAll"
          ? coursesGrouped
          : coursesGrouped.filter((course) => (course.courseStatus || "n/a") === statusFilter);
  
        setGroupedCourses(filteredCourses);
      } else {
        console.error("Error in payment history data:", paymentHistoryData.error);
        setGroupedCourses([]);
      }
    }
  }, [paymentHistoryData, statusFilter]);

  const handleStatusChange = (newStatus) => {
    setStatusFilter(newStatus);
  };

  const renderContent = () => {
    if (paymentHistoryDataLoading) {
      return null; // Or return a loading spinner component
    }

    if (!groupedCourses || groupedCourses.length === 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
            width: '100%',
         
          
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#000000',
              fontWeight: 500,
              textAlign: 'center',
              fontFamily : "Gilroy Medium"
            }}
          >
            No data available
          </Typography>
        </Box>
      );
    }

    return (
      <PurchaseHistoryTable
        groupedCourses={groupedCourses}
        onStatusChange={handleStatusChange}
        statusFilter={statusFilter}
      />
    );
  };

  return (
    <Box 
      className="purchase-history-page"
      sx={{
        width: '100%',
        maxWidth: '100vw',
        overflow: 'hidden',
        padding: { 
          xs: '16px',
          sm: '24px'
        },
        '& .MuiTableContainer-root': {
          margin: { 
            xs: '-16px',
            sm: '0'
          },
          width: { 
            xs: 'calc(100% + 32px)',
            sm: '100%'
          }
        },
        '& .toggle-button-group': {
          margin: {
            xs: '0 0 16px 0',
            sm: '0 0 24px 0'
          },
          width: {
            xs: '100%',
            sm: 'auto'
          }
        }
      }}
    >
      <Box 
        sx={{ 
          marginBottom: { 
            xs: '16px',
            sm: '24px'
          }
        }}
      >
        <Header
          title="Purchase History"
          subtitle="Manage your recent orders and invoices"
        />
      </Box>

      <Box 
        sx={{ 
          width: '100%',
          overflowX: 'auto',
          WebkitOverflowScrolling: 'touch',
          msOverflowStyle: '-ms-autohiding-scrollbar',
          '& ::-webkit-scrollbar': {
            height: '6px',
            backgroundColor: '#f5f5f5'
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '3px'
          }
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
};

export default PurchaseHistoryPage;