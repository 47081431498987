import { Box, Button } from "@mui/material";
import React, { useState, useRef, useEffect, useContext } from "react";
import QuizFeedback from "./QuizFeedback";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import LessonContext from "context/lessonContext/LessonContext";
import QuizTimer from "./TimerCard";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import usePostRequest from "hooks/usePostRequest";
import QuizResultCard from "./QuizResultCard";

const HtmlCodeQuiz = ({ content }) => {
  const { lessonInfo, lessonIdentifiers } = useContext(LessonContext);
  const { message, notifyUser, clearUserNotification } =
    useContext(NofityUserContext);
  const [display, setLoading] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const questionRef = useRef(null);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const { updateLessonCompletionData } = useContext(LessonContext);

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  const { data: saveQuixResponseData, postData: saveQuixResponse } =
    usePostRequest(API_ENDPOINTS.SAVE_HTML_QUIZ_PROGRESS);

  useEffect(() => {
    setTimerKey(timerKey + 1); // Reset timer when quiz starts
  }, [quizStarted]);

  useEffect(() => {
    if (lessonInfo) {
      const foundContent = lessonInfo.find(item => item.content_id === content);
      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
      } else {
        console.warn("Content not found for the provided content_id:", content);
      }
    } else {
      console.warn("lessonInfo is not available.");
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.htmlCodeDetails.quiz_ht_id, lessonIdentifiers?.course_id)
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);

  useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      // Check if quizfeedBackStat is null or empty
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      // Check if quizStatDetails is null or empty
      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true);
      }
    }
  }, [quizStatResponse, contentDetails1]);

  // Old useEffect to check if all input fields are filled and unique words are entered

  // useEffect(() => {
  //   const checkInputFieldsFilled = () => {
  //     // Select all contenteditable cells that are not marked as non-editable
  //     const editableCells = document.querySelectorAll(
  //       '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
  //     );
  
  //     // Collect all words entered in the cells
  //     const enteredWords = new Set();
  //     let allValid = true;
  
  //     // Check each cell for uniqueness
  //     editableCells.forEach((cell) => {
  //       const text = cell.textContent.trim().toLowerCase();
  
  //       if (text !== "") {
  //         if (enteredWords.has(text)) {
  //           // If the word is already used, invalidate and show an alert
  //           notifyUser("Duplicate Word", `The word "${text}" is already used. Please enter a unique word.`, "error");
  //           cell.textContent = ""; // Clear the duplicate input
  //           allValid = false; // Mark as invalid
  //         } else {
  //           enteredWords.add(text); // Add the word to the set
  //         }
  //       }
  //     });
  
  //     // Check if all editable cells have some content and are valid
  //     const allFilled = Array.from(editableCells).every(
  //       (cell) => cell.textContent.trim() !== ""
  //     );
  
  //     setAllInputsFilled(allFilled && allValid);
  //   };
  
  //   // Check inputs when the quiz starts and add event listeners
  //   if (quizStarted) {
  //     checkInputFieldsFilled();
  
  //     // Select all contenteditable cells
  //     const editableCells = document.querySelectorAll(
  //       '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
  //     );
  
  //     // Add input event listeners to each cell
  //     editableCells.forEach((cell) => {
  //       cell.addEventListener("input", checkInputFieldsFilled);
  //     });
  
  //     return () => {
  //       // Cleanup event listeners
  //       editableCells.forEach((cell) => {
  //         cell.removeEventListener("input", checkInputFieldsFilled);
  //       });
  //     };
  //   }
  // }, [quizStarted]);

  const validateInput = (event) => {
    const cell = event.target;
    const text = cell.textContent.trim();
    
    // Remove any non-letter characters
    const cleanText = text.replace(/[^a-zA-Z]/g, '');
    
    // If the text was modified (contained invalid characters)
    if (cleanText !== text) {
      event.preventDefault();
      cell.textContent = cleanText;
      notifyUser(
        "Invalid Input",
        "Only letters are allowed. Numbers and special characters will be removed.",
        "warning"
      );
    }
  };
  
  useEffect(() => {
    const checkInputFieldsFilled = () => {
      const editableCells = document.querySelectorAll(
        '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
      );
  
      const enteredWords = new Set();
      let allValid = true;
  
      editableCells.forEach((cell) => {
        const text = cell.textContent.trim().toLowerCase();
        
        // Check if the text contains only letters
        if (!/^[a-zA-Z]*$/.test(text)) {
          cell.textContent = text.replace(/[^a-zA-Z]/g, '');
          notifyUser(
            "Invalid Input",
            "Only letters are allowed. Numbers and special characters will be removed.",
            "warning"
          );
          allValid = false;
        }
  
        if (text !== "") {
          if (enteredWords.has(text)) {
            notifyUser(
              "Duplicate Word",
              `The word "${text}" is already used. Please enter a unique word.`,
              "error"
            );
            cell.textContent = "";
            allValid = false;
          } else {
            enteredWords.add(text);
          }
        }
      });
  
      const allFilled = Array.from(editableCells).every(
        (cell) => cell.textContent.trim() !== ""
      );
  
      setAllInputsFilled(allFilled && allValid);
    };
  
    if (quizStarted) {
      const editableCells = document.querySelectorAll(
        '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
      );
  
      editableCells.forEach((cell) => {
        cell.addEventListener("input", validateInput);
        cell.addEventListener("input", checkInputFieldsFilled);
        
        // Add paste event listener to clean pasted content
        cell.addEventListener("paste", (e) => {
          e.preventDefault();
          const text = e.clipboardData.getData("text/plain");
          const cleanText = text.replace(/[^a-zA-Z]/g, '');
          document.execCommand("insertText", false, cleanText);
        });
      });
  
      return () => {
        editableCells.forEach((cell) => {
          cell.removeEventListener("input", validateInput);
          cell.removeEventListener("input", checkInputFieldsFilled);
        });
      };
    }
  }, [quizStarted]);

  useEffect(() => {
    if (quizStatApiResponse) {
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_ht_id === detail.response_ht_id
        );

        // Ensure MasterQuestion and StoryQuestion are defined
        const question = detail.MasterQuestion ? detail.MasterQuestion.question : '';

        return {
          id: detail.response_ht_id,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: detail.MasterQuestion ? detail.MasterQuestion.score : 0,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });

      setModalData(combinedData);
    }
  }, [quizStatApiResponse]);

  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };

  const handleCellClick = (event) => {
    const cell = event.target;
    if (cell.classList.contains("html_structure_wrapper__html_table_selected")) {
      cell.classList.remove("html_structure_wrapper__html_table_selected");
      setSelectedCount(prevCount => prevCount - 1);
    } else {
      if (selectedCount < 5) {
        cell.classList.add("html_structure_wrapper__html_table_selected");
        setSelectedCount(prevCount => prevCount + 1);
      } else {
        alert("You can only select up to 5 squares.");
      }
    }
  };

  // Attach and cleanup event listeners for cell clicks
  useEffect(() => {
    const cells = document.querySelectorAll("#coloringBoxTable td");
    cells.forEach(cell => cell.addEventListener("click", handleCellClick));

    return () => {
      cells.forEach(cell => cell.removeEventListener("click", handleCellClick));
    };
  }, [selectedCount]);

  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.htmlCodeDetails.quiz_ht_id, lessonIdentifiers?.course_id);
      const timer = setTimeout(() => {
        setQuizSubmitted(false);
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };

  const handleSave = () => {
    if (!allInputsFilled) return; // Prevent saving if not all inputs are filled

    setIsSaving(true);
    const htmlContent = questionRef.current.innerHTML;
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);
    
    // Collect answers from editable cells
    const answers = [];
    const editableCells = document.querySelectorAll(
      '.html_structure_wrapper__tic-tac-toe__cell[contenteditable="true"]'
    );

    editableCells.forEach((cell) => {
      answers.push(cell.textContent.trim());
    });

    const payload = {
      course_id: lessonIdentifiers.course_id,
      quiz_ht_id: contentDetails.htmlCodeDetails.quiz_ht_id,
      question: contentDetails.questions[0].question_id,
      answer_text: htmlContent,
      completion_time: timeTakenInMinutes,
      answers: answers // Optional: include collected answers in the payload
    };

    const payloadArray = [payload];

    saveQuixResponse(payloadArray);
    setQuizSubmitted(true);
    updateLessonCompletionData(content);
    notifyUser(
      "Quiz Submitted",
      "Your responses have been saved successfully!",
      "success"
    );
  };

  const handleTimeUp = async () => {
    questionRef.current.innerHTML = "";
  };

  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return <QuizResultCard
      data={modalData}
      title={quizStatApiResponse.quizStatDetails[0].MasterQuizHtmlQuiz.quiz_name}
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      status="Submitted"
    />
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return <QuizResultCard
      data={modalData}
      title={
        quizStatApiResponse.quizStatDetails[0].MasterQuizHtmlQuiz
          .quiz_name
      }
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
      status="Graded"
    />
  }

  return display ? (
    <>
      {!quizStarted ? (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      ) : (
        <Box className="AFQ_wrapper">
          {contentDetails.htmlCodeDetails.estimated_completion_time && (
            <Box className="AFQ_wrapper__time">
              <QuizTimer 
                key={timerKey} 
                estimatedCompletionTime={contentDetails.htmlCodeDetails.estimated_completion_time} 
                handleComplete={handleTimeUp}
              />
            </Box>
          )}
          <Box
            ref={questionRef}
            className="AFQ_wrapper__long-question-wrapper"
            dangerouslySetInnerHTML={{
              __html: contentDetails.questions[0]?.question.replace(/&nbsp;/g, " ") || "",
            }}
          />
          <Box
            ref={questionRef}
            className="AFO_wrapper__answer-wrapper"
            dangerouslySetInnerHTML={{
              __html: contentDetails.htmlCodeDetails.html_question.replace(/&nbsp;/g, " ") || "",
            }}
          />
          <Box
            className="SAQ_wrapper-navigate"
            sx={{ display: "flex", justifyContent: "flex-end" , marginTop: "20px", paddingTop: "20px", }}
          >
            <Button
              className="SAQ_wrapper-navigate__button"
              variant="outlined"
              onClick={handleSave}
              disabled={!allInputsFilled || isSaving}
            >
             {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      )}
    </>
  ) : (<></>);
};

export default HtmlCodeQuiz;