import { Box, Typography } from "@mui/material";
import React from "react";
import annotationAlert from "assets/images/annotation-alert.svg";
import chevronup from "assets/images/chevron-up.svg";
import {Link , useParams } from "react-router-dom"
const CourseForumCard = () => {
  const { slug } = useParams();
  return (
    <Box className="course-forum-card">
      <Typography component="body2" className="course-forum-card--num">
        01
      </Typography>
      <Box
        component="img"
        src={annotationAlert}
        alt="annotation-alert"
        className="course-forum-card--annotation-image"
      />
      <Typography component="h6" className="course-forum-card--course-forum">
        Course Forum
      </Typography>
      <Typography
        component="body2"
        className="course-forum-card--course-forum--description"
      >
        Collaborative learning space where students discuss topics, share insights, and solve problems together.
      </Typography>
      <Link to={`/app/forum/${slug}`}>
      <Box className="course-forum-card--visit-forum">
        <Typography
          component="body2"
          className="course-forum-card--visit-forum-text"
        >
          Visit Forum
        </Typography>
        <Box
          className="course-forum-card--visit-forum-icon"
          component="img"
          src={chevronup}
          alt="annotation-alert"
        />
      </Box>
      </Link>
    </Box>
  );
};

export default CourseForumCard;
