import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import React, { useState } from "react";

const MCQ = () => {
  const options = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const [activeOption, setActiveOption] = useState(null);
  //set active state
  const handleOptionClick = (index) => {
    setActiveOption(index);
  };
  return (
    <Box className="MCQ_wrapper">
      <Box className="MCQ_wrapper__Question-box">
        <Box className="MCQ_wrapper__Question-box--number">
          <Typography className="MCQ_wrapper__Question-box--number-text">
            {" "}
            Question 5/10
          </Typography>
        </Box>
        <Typography className="MCQ_wrapper__Question-box--question">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </Typography>
      </Box>
      <Box className="MCQ_wrapper__answer-wrapper">
        <Box className="MCQ_wrapper__options-wrapper">
          <Grid container spacing={4}>
            {options.map((option, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  className={`${
                    activeOption === index
                      ? "MCQ_wrapper__options-wrapper--option-button--active"
                      : "MCQ_wrapper__options-wrapper--option-button"
                  }`}
                  onClick={() => handleOptionClick(index)}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box className="MCQ_wrapper-navigate" id="no-bg">
          <Button
            className="MCQ_wrapper-navigate__button"
            variant="outlined"
            id="white-bg"
          >
            Previous
          </Button>
          <Button
            className="MCQ_wrapper-navigate__button"
            variant="outlined"
            id="white-bg"
          >
            Next 
          </Button>
        </Box>
      </Box>
      <LinearProgress
        className="MCQ_wrapper__progress"
        variant="determinate"
        value={50}
      />
    </Box>
  );
};

export default MCQ;
